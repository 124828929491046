/*jshint esversion: 6 */
import '../style.css';
import './toscana.css';

import storage from '../../../storage.js';
import global from '../../../global.js';
// import * as tools from '../../../tools.js';
import dbmng from '../../../ext/dbmng.js';

// ---- file duplicato da ripulire ----
import * as partecipa from '../../../core/partecipa/partecipa_new.js';

// Se serve GPS
//*********************************************************************
import mapHelper from '../../../core/map.js';
import gps from '../../../core/gps.js';
//*********************************************************************


// TRADUZIONE
//*********************************************************************
import t from '../../../translate.js';
//*********************************************************************

import * as db from '../data.js';
import * as commonJS from '../commonJS.js';
import ae from '../index.js';
var aedita=new ae({style:"f7"});

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************


// Variabili globali
//*********************************************************************

global.base_link='https://agroambiente.info.regione.toscana.it/agro18/';
if(location.origin!='http://localhost:8080'){
  global.redirect_uri=location.origin+'/agro18/app';
}

global.base_path='https://agroambiente.info.regione.toscana.it/agro18/api/';
global.base_call=global.base_path+'userplus/';
global.base_call_aedita=global.base_path;
global.nomeApp='AgroAmbiente Toscana';
global.download_app_path='https://agrotosc.aedit.it/app/AgroAmbienteToscana.apk';
global.useSocialLogin=true;
global.language='it';
global.settingsName='agroambiente_toscana';
global.nomeRegione='Toscana';
global.default_coordinates=[43.50075,11.06323];
global.default_zoom=7;

global.app_version='1.0.10'

//*********************************************************************

global.firebaseConfig = {
  apiKey: "AIzaSyBfy_iDeBtJTlZXaAYHITwLQ3c_P6QHAFo",
  authDomain: "agroambiente-toscana.firebaseapp.com",
  projectId: "agroambiente-toscana",
  storageBucket: "agroambiente-toscana.appspot.com",
  messagingSenderId: "1079691263011",
  appId: "1:1079691263011:web:d02395c03d5beec1878596",
  measurementId: "G-8M5JYXHMV4"
};

if (typeof firebase!=='undefined') {
  global.firebase=firebase.initializeApp(global.firebaseConfig);

  global.messaging = null;

  if (firebase.messaging.isSupported()){
    global.messaging = firebase.messaging();
  }
}




//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router.on({
    'monitoring': function () {
      // cleanPage();
      // location.reload()
      if (jQuery('.left .f7-icons').text()=='chevron_left') {
        location.reload();
      }
      //TODO ???si usa l'uid() aedita.monitoraggio(uid);
      var s=storage.get();
      if (s.user) {
        aedita.monitoraggio();
      }
      else {
        console.log(location.hash);
        location.hash = '#login';
      }
    },
    // 'view_data': function () {
    //   if (jQuery('.left .f7-icons').text()=='chevron_left') {
    //     location.reload();
    //   }
    //   // cleanPage();
    //   //TODO ???si usa l'uid() aedita.monitoraggio(uid);
    //   partecipa.visualizzazioneDati();
    // },
    'aggiungi_azienda': function () {
      cleanPage();
      aedita.creaAzienda();
    },
    'bollettini/:tipo': function (param) {
      cleanPage();
      aedita.bollettini(param.tipo);
    },
    'libreria': function () {
      cleanPage();
      aedita.libreria();
    },
    'info_utili': function(){
      cleanPage();
      aedita.info_utili();
    },
    'data_storic/:id_field/:fieldName/:varieta/crop/:id_crop': function (param) {
      cleanPage();
      aedita.showStoricData(param.id_field,param.id_crop,param.fieldName,param.varieta);
    },
    'data_storic/:id_field/:fieldName/crop/:id_crop/schema/:id_survey_schema': function (param) {
      cleanPage();
      aedita.getStoricTable('storicTable',param.id_survey_schema,param.id_field,'storic');
    },
    'data_entry/:id_field/:fieldName/crop/:id_crop/schema/:id_survey_schema/data/:data_monitoraggio': function (param) {
      cleanPage();
      aedita.openDataEntry(param.id_crop,param.id_field,param.fieldName,param.varieta,param.id_survey_schema,param.data_monitoraggio);
    },
    'data_entry/:id_field/:fieldName/:varieta/crop/:id_crop': function (param) {
      cleanPage();
      aedita.openDataEntry(param.id_crop,param.id_field,param.fieldName,param.varieta);
    },
    'data_entry/crop/:id_crop': function (param) {
      cleanPage();
      console.log("inserimento multiplo con crop");
      aedita.openDataEntry(param.id_crop);
    },
    'data_entry/generic': function () {
      cleanPage();
      console.log("inserimento multiplo senza crop");
      aedita.openDataEntry();
    },
    'edit_farm_center/:id_farm_center': function (param) {
      cleanPage();
      console.log(global.idFarmCenterInEditing);
      global.idFarmCenterInEditing=param.id_farm_center;
      console.log(global.idFarmCenterInEditing);
      aedita.creaAzienda('update');
    },
    'delete_survey/:id_survey/schema/:id_survey_chema/field/:id_field': function (param) {
      cleanPage();
      aedita.eliminaSurvey(param.id_survey,param.id_survey_chema,param.id_field);
    },
    'delete_survey/:id_survey/schema/:id_survey_chema/field/:id_field/storic': function (param) {
      cleanPage();
      aedita.eliminaSurvey(param.id_survey,param.id_survey_chema,param.id_field,'storic');
    },
    'tile/:lat/:lon/:layer': function (param) {
      cleanPage();

      var pos={
        bufferedLatitude:param.lat,
        bufferedLongitude:param.lon,
      }
      aedita.getTiles(pos, param.layer);
    }
  });

  router=partecipa.exe_routes(router);
  return router;
}

export function tools(){
  partecipa.partecipa_tools();
}

function cleanPage(){
  var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">'+('Back')+'</span></a>';
  jQuery('.left').html(html);

  jQuery('#download_app').hide();

  jQuery('#footer').parent().show();
  jQuery('#footer').html('');
}

// export function home_page(){
//   var s=storage.get();
//   var title='AgroAmbiente.Toscana';
//   var html='';
//   html+='<div class="rozw no-gap home_buttons">';
//
//
//
//   html+='<div class="col-50">';
//   html+='<a href="#view_data" class="button button-raised button-fill button-round link external" style="margin:5px;">Visualizza Monitoraggio</a>';
//   html+='</div>';
//
//
//   if (s.user) {
//
//
//       var canInsert=false;
//       jQuery.each(s.user.roles,function(k,v){
//         if (v=='monitor' || v=='adminmonit' || v=='adminfito') {
//           canInsert=true;
//         }
//       });
//
//       if (canInsert) {
//         html+='<div class="col-50">';
//           html+='<a href="#monitoring" class="button button-raised button-fill button-round link external" style="margin:5px;">Monitoraggio Tecnici</a>';
//         html+='</div>';
//       }
//
//       var showPartecipa=false;
//
//       jQuery.each(s.user.roles,function(k,v){
//         if (v=='partecipa' || v=='libreria' || v=='info_utili' || v=='adminfito') {
//           showPartecipa=true;
//         }
//       });
//
//       if (showPartecipa) {
//         html+='<div class="col-50">';
//           html+='<a href="#partecipa" class="button button-raised button-fill button-round link external" style="margin:5px;">Monitoraggio Aziendale</a>';
//         html+='</div>';
//
//       }
//     }
//     else {
//       html+='<div class="col-50">';
//         html+='<a href="#login" class="button button-raised button-fill button-round link external" style="margin:5px;">Fai il login</a>';
//       html+='</div>';
//     }
//
//
//   html+='</div>';
//
//   var page={
//     exclude_card:true,
//     title: title,
//     content: html
//   };
//
//   global.theme.render(page);
//
// }

export function home_page(){
  var map=new mapHelper();

  var s=storage.get();
  var title='AgroAmbiente.Toscana';
  var html=`
    <div id="agroMap">${map.getHtml(false)}<div>
    <div id="data_content"><div>
  `;

  var page={
    exclude_card:true,
    title: title,
    content: html
  };

  global.theme.render(page);

  jQuery('#agroMap').parent().css('margin',0);
  // jQuery('#agroMap #map_container').css('height',outerHeight/2.2);
  jQuery('#runGPS').parent().remove();

  commonJS.createMap('map_container',{latitude:global.default_coordinates[0],longitude:global.default_coordinates[1]},8);

  jQuery('#data_content').html("Sto cercando la posizione...");
  try {
    localRunGPS();
  }
  catch (e) {
    jQuery('#data_content').html("Non è stato possibile trovare la posizione.");
  }
}

export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Dati', 'icon_f7':'map_pin_ellipse', 'href':'#view_data'}
  ];

  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;

    var showPartecipa=true;
    var showMonitor=false;

    jQuery.each(s.user.roles,function(k,v){
      // if (v=='partecipa') {
      //   showPartecipa=true;
      // }
      if (v=='adminmonit' || v=='monitor') {
        showMonitor=true;
      }

    });

    if (showMonitor) {
      sidebars.push({'label': 'Monitoraggio', 'icon_f7':'list_bullet_below_rectangle', 'href':'#monitoring'});
    }
    if (showPartecipa) {
      sidebars.push({'label': 'Partecipa', 'icon_f7':'doc_append', 'href':'#partecipa'});
    }

  }

  theme_param.sidebars=sidebars;
  theme_param.title=global.nomeApp;

  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}

export function synchronize(){
  db.sync(function(){console.log("Sync");},function(e){console.log(e);},true);
}

function localRunGPS(){
  var self=this;
  gps.startGPS({
    getFasterPosition:true,
    save_place: true, //dentro la libreria chiama il reverse geocode e lo salva in global.coordinates
    buffer_max_size: 22222222222,
    position: function(pos){
      if (global.blocked_pos!==true) {
        console.log("KLEAN",pos);
        pos.timestamp=new Date().getTime();
        global.coordinates=pos;

        console.log(pos);

        setTimeout(function(){
          global.marker = L.marker([pos.bufferedLatitude,pos.bufferedLongitude]).addTo(global.map);
        },1000);
        console.log(pos);

        global.map.panTo([pos.bufferedLatitude,pos.bufferedLongitude],{animate:true});

        aedita.getTiles(pos);


        global.map.on("click", function(e){
          if (global.marker) {
            global.map.removeLayer(global.marker)
          }
          global.marker = new L.Marker([e.latlng.lat, e.latlng.lng]).addTo(global.map);
          global.map.panTo([e.latlng.lat, e.latlng.lng],{animate:true});

          aedita.getTiles({"bufferedLatitude":e.latlng.lat, "bufferedLongitude":e.latlng.lng});
        });

      }
      else {
        console.log("posizione bloccata...");
      }
    }
  });

}
