/*jshint esversion: 6 */

import sortable from 'sorttable';
import dbmng from '../../ext/dbmng.js';
import monitoring from "./data.js";
import * as commonJS from "./commonJS.js";
import * as index from "./index.js";

import * as marker_red from './media/marker-red.png';
import * as marker_shadow from './media/marker-shadow.png';


import global from '../../global.js';
import storage from '../../storage.js';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export function markerMyFarmCenters(farmCenters){
  var minCorner={lat:null,lon:null};
  var maxCorner={lat:null,lon:null};
  jQuery.each(farmCenters,function(k,v) {
    if (v.lat!=0 && v.lon!=0) {
      var popup='';
      popup +='<div style="text-align:center;">';
      popup +=v.name;
      popup +='<br>';
      popup +='<a style="align-text:center;" onclick="location.hash=\'/edit_farm_center/'+v.id_farm_center+'\'">Modifica!</a>';
      popup +='<br>';
      popup +='<a style="align-text:center;" onclick="location.hash=\'/delete_farm_center/'+v.id_farm_center+'\'">Elimina!</a>';
      popup +='</div>';

      global.marker = L.marker([v.lat, v.lon]).bindPopup(popup);
      global.marker.addTo(global.map);

      if (minCorner.lat!=null) {
        if (v.lat<minCorner.lat) {
          minCorner.lat=v.lat;
        }
      }
      else {
        minCorner.lat=v.lat;
      }
      if (minCorner.lon!=null) {
        if (v.lon<minCorner.lon) {
          minCorner.lon=v.lon;
        }
      }
      else {
        minCorner.lon=v.lon;
      }


      if (maxCorner.lat!=null) {
        if (v.lat>maxCorner.lat) {
          maxCorner.lat=v.lat;
        }
      }
      else {
        maxCorner.lat=v.lat;
      }
      if (maxCorner.lon!=null) {
        if (v.lon>maxCorner.lon) {
          maxCorner.lon=v.lon;
        }
      }
      else {
        maxCorner.lon=v.lon;
      }
    }
  });

  if (minCorner.lat!=null && minCorner.lon!=null && maxCorner.lat!=null && maxCorner.lon!=null) {
    var bounds = L.latLngBounds([[minCorner.lat,minCorner.lon], [maxCorner.lat, maxCorner.lon]]);
    global.map.fitBounds(bounds);
  }
}

export function showFields(div){

  var s=storage.get();
  var request={
    uid:global.uid,
    access_token:s.user.access_token,
    anno_visualizzazione:jQuery('#anno_visualizzazione').val()
  };


  var mon= new monitoring();
  var get_fields=mon.get_fields(request);

  get_fields
  .then( (fields) => {
    printFields(fields,div);

    // console.log(storage.get());
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });
}

export function printFields(fields,div){
  global.farm_home_path=window.location.href;

  if (global.style=='f7') {
    var table='';

    // console.log(global.aedita_opt);
    // console.log(storage.get());

    if(global.nomeRegione=='Abruzzo'){
      table+="<div class='block block-strong f7-info' id='monitoring_disclainer'>";
      table+="La Regione Abruzzo mette gratuitamente a disposizione dei tecnici esterni l’app e la relativa piattaforma. La       collaborazione è a carattere volontario, senza nulla a pretendere nei confronti della Regione.";
      table+=" Ai fini dei monitoraggi aziendali le credenziali di accesso vengono fornite con l’impegno ad inserire i dati di       campo con regolarità (invio settimanale), preferibilmente nelle giornate del lunedì o martedi.       In perdurante assenza dei predetti inserimenti, l’accesso alla piattaforma sarà revocato.";
      table+=" Le aziende monitorate devono essere registrate in maniera anonima sul sito, dunque non con dati anagrafici ma con semplici acronimi o nomi di fantasia.";
      table+=" La geolocalizzazione può essere fatta anche nelle immediate vicinanze su strada pubblica.      La Regione Abruzzo gestisce liberamente i dati di campo inseriti per le proprie finalità divulgative.</div>";
    }


    table+='<div class="list media-list">';
      table+='<ul>';
        jQuery.each(fields.data,function(k,v) {
          var azName=v.name;
          if (v.name.trim()=='') {
            azName='<i>Nome sconosciuto</i>';
          }
          table+='<li>';
            table+='<a href="#/data_entry/'+v.id_field+'/'+(v.name).replace(/'/g, '’')+'/'+dashIfIsNull((v.cultivar).replace(/'/g, '’'))+'/crop/'+v.id_crop+'" class="item-link link external item-content">';
              table+='<div class="item-inner">';
                table+='<div class="item-title-row">';
                  table+='<div class="item-title">'+azName+'</div>';
                  table+='<div class="item-after">Inserisci</div>';
                table+='</div>';
                table+='<div class="item-subtitle">'+v.place+'</div>';
                table+='<div class="item-text">'+v.crop+' - '+commonJS.dashIfIsNull(v.cultivar)+'</div>';
              table+='</div>';
            table+='</a>';
          table+='</li>';
        });
      table+='</ul>';
    table+='</div> ';

    jQuery('#'+div).html(table);

  }
  else {
    var table='';
    table+='<table class="table">';
    table+='<thead>';
    table+='<tr>';
      if (window.innerWidth>320 && window.innerWidth<599) {
        if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
          table+='<th>Aggiungi</th>';
        }
        table+='<th>Mostra</th>';
      }

      table+='<th>Nome</th>';
      table+='<th class="hideInSmartphone">Località</th>';
      table+='<th>Coltura</th>';
      table+='<th>Varietà</th>';

      if (window.innerWidth<320 || window.innerWidth>599) {
        if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
          table+='<th>Aggiungi</th>';
        }
        table+='<th>Mostra</th>';
      }
    table+='</tr>';
    table+='</thead>';
    table+='<tbody>';
    console.log(global.aedita_opt);
    jQuery.each(fields.data,function(k,v) {

      // var dataInField=surveys4field.filter(function(f){return (v.id_field==f.id_field);});
      var dataInField=true;
      if(typeof v.count!=='undefined'){
        if(v.count==0){
          dataInField=false;
        }
      }
      var className='';
      if (v.temporary==1) {
        className='temporary';
      }
      table+='<tr class="'+className+'">';
      if (window.innerWidth>320 && window.innerWidth<599) {
        if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
          table+='<td><i class="f7-icons" onclick="location.hash=\'/data_entry/'+v.id_field+'/'+(v.name).replace(/'/g, '’')+'/'+dashIfIsNull((v.cultivar).replace(/'/g, '’'))+'/crop/'+v.id_crop+'\'">plus_app_fill</i></td>';
        }
        if (dataInField) {
          // showStoricData(v.id_field,v.id_crop,v.name,v.cultivar);

          table+='<td><i class="f7-icons" onclick="location.hash=\'/data_storic/'+v.id_field+'/'+(v.name).replace(/'/g, '’')+'/'+dashIfIsNull((v.cultivar).replace(/'/g, '’'))+'/crop/'+v.id_crop+'\'">table</i></td>';
        }
        else {
          table+='<td><i onclick="alert(\'Non ci sono dati disponibili per questo punto\')" class="fa fa-ban"></i></td>';
        }
      }

      var azName=v.name;
      if (v.name.trim()=='') {
        azName='<i>Nome sconosciuto</i>';
      }
      table+='<td><a onclick="location.hash=\'/edit_farm_center/'+v.id_farm_center+'\'">'+azName+'</a></td>';
      table+='<td class="hideInSmartphone">'+v.place+'</td>';

      if (commonJS.is_cordova()) {
        table+='<td>';
        // <img src="http://192.168.113.88/DOWNLOAD/crop-icons/'+v.icon+'.svg" width="30" height="30">
        table+='</td>';
      }
      else {
        table+='<td>'+v.crop+'</td>';
      }

      table+='<td>'+commonJS.dashIfIsNull(v.cultivar)+'</td>';
      // table+="<td><button class='btn btn-default' onclick='location.hash=\'/data_entry/"+v.id_field+"/"+location.hash.split("/")[3]+"/crop/"+location.hash.split("/")[5]+"/schema/"+v.id_survey_schema+"/data/"+v.date+"\"'>Aggiungi dati</button></td>";

      if (window.innerWidth<320 || window.innerWidth>599) {
        if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
          table+='<td><button class="btn btn-default" onclick="location.hash=\'/data_entry/'+v.id_field+'/'+(v.name).replace(/'/g, '’')+'/'+dashIfIsNull((v.cultivar).replace(/'/g, '’'))+'/crop/'+v.id_crop+'\'">Aggiungi dati</button></td>';
        }
        if (dataInField) {
          table+='<td><button class="btn btn-default" onclick="location.hash=\'/data_storic/'+v.id_field+'/'+(v.name).replace(/'/g, '’')+'/'+dashIfIsNull((v.cultivar).replace(/'/g, '’'))+'/crop/'+v.id_crop+'\'">Mostra dati</button></td>';
        }
        else {
          table+='<td> Non sono disponibili dati per questo punto </td>';
        }
      }


      table+='</tr>';
    });

    table+='</tbody></table>';

    var id_org=0;//TODO Fix id_org surveys4field.org
    if (id_org==1 && !commonJS.is_cordova()) {
      table+='<button class="btn btn-default" onclick="location.hash=\'/rapid_data_entry/crop/'+2+'\'">Inserimento veloce mosca</button>';
    }
    jQuery('#'+div).html(table);


    var tt_dom = jQuery('#'+div + ' table')[0];
    sorttable.makeSortable(tt_dom);

  }
}

export function dashIfIsNull(value){
  var returnValue=value;
  if (value==null) {
    returnValue='---';
  }
  else if (value=='') {
    returnValue='---';
  }

  if (typeof value!='string') {
    if (isNaN(value)) {
      returnValue='---';
    }
  }
  else {
    if (value=='NaN') {
      returnValue='---';
    }
  }

  return returnValue;
}

export function printSchemasStoric(schemas,id_field,fieldName,id_crop){
  var s=storage.get();
  var request={
    uid:global.uid,
    access_token:s.user.access_token,
    id_field:id_field,
    anno_visualizzazione:jQuery('#anno_visualizzazione').val()
  };

  var mon= new monitoring();
  var get_storic_survey=mon.get_storic_survey(request);

  get_storic_survey
  .then( (schemasData) => {
    printStoricSurvey(schemasData,schemas,id_field,fieldName,id_crop);
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });
}

export function getStoricTable(div, id_survey_schema,id_field,className,method){
  if (typeof method!='undefined') {
    method=method;
  }
  else {
    method='html';
  }


  var storic=false;
  if (typeof className!='undefined') {
    if (className=='storic') {
      storic=true;
    }
  }

  var s=storage.get();
  var input_data={
    id_survey_schema:id_survey_schema,
    id_field:id_field,
    access_token:s.user.access_token,
    anno_visualizzazione:jQuery('#anno_visualizzazione').val()
  };

  var mon= new monitoring();
  var get_storic_survey=mon.get_storic_survey(input_data);

  get_storic_survey
  .then( (storicSurveys) => {
    printStoricTable(storicSurveys,method,storic,id_survey_schema,id_field,div);
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });
}

export function printStoricSurvey(schemasData,schemas,id_field,fieldName,id_crop){
  schemasData=schemasData.data;
  var tab="";
  tab+='<ul class="nav navbar-nav">';
  jQuery.each(schemas,function(k,v){
    var surveyInSchema=schemasData.filter(function(f){return (v.id_survey_schema==f.id_survey_schema);});

    if (surveyInSchema.length>0) {
      tab+='<li class="schemaInTab" value="'+v.id_survey_schema+'" id="'+v.id_survey_schema+'"><a id="'+v.id_survey_schema+'">'+v.name+'</a></li>';
    }

    if (!commonJS.is_cordova()) {
      console.log(index);
      getStoricTable('printStoricTable',v.id_survey_schema,id_field,'storic','print');
    }

  });
  tab+='</ul>';

  jQuery('#schmasTab').html(tab);

  jQuery('.schemaInTab a').click(function() {
    jQuery('.schemaInTab a').removeClass('active');
    jQuery(this).addClass('active');

    location.hash='/data_storic/'+id_field+'/'+fieldName+'/crop/'+id_crop+'/schema/'+jQuery(this).attr('id');
  });
  jQuery('.schemaInTab a').first().trigger('click');
}

export function printStoricTable(storicSurveys,method,storic,id_survey_schema,id_field,div){
  var table='';
  if (method=='print') {
    table+='<h3>'+storicSurveys.survey_schema.data[0].name+'</h3>';
  }

  if (storicSurveys.data.length>0) {
    var tableClass='';
    if (!storic) {
      tableClass='storicData';
    }

    if (global.style=='f7') {
      table+='<table class="'+tableClass+' ">';
    }
    else {
      table+='<table class="table '+tableClass+' ">';
    }
    table+='<thead>';
      table+='<tr>';
        if (global.style=='f7') {
          table+='<th class="medium-only">Data</th>';
          jQuery.each(storicSurveys.survey_var.data, function(k,v){
            table+="<th class='medium-only'>"+v.name+"</th>";
          });
          table+="<th class='medium-only'>Note</th>";

          table+="<th class='medium-only hideInPrint'>Modifica</th>";
          table+="<th class='medium-only hideInPrint'>Elimina</th>";

        }
        else {
          table+='<th>Data</th>';
          jQuery.each(storicSurveys.survey_var.data, function(k,v){
            table+="<th>"+v.name+"</th>";
          });
          table+="<th>Note</th>";

          table+="<th class='hideInPrint'>Modifica</th>";
          table+="<th class='hideInPrint'>Elimina</th>";
        }

      table+='</tr>';
    table+='</thead>';
    table+='<tbody>';
    jQuery.each(storicSurveys.data, function(k,v) {
      table+='<tr>';
      table+='<td>'+v.date+'</td>';
      var val=JSON.parse(v.val);
      jQuery.each(storicSurveys.survey_var.data, function(j,l){
        var value=val[l.var_name];
        if(typeof value=="undefined"){
          value="-";
        }
        else if(value===null){
          value="-";
        }
        else {
          if (l.widget=='select') {
            jQuery.each(storicSurveys.survey_code.data,function(i,d) {
              if (d.id_survey_code+""==value) {
                value=d.name;
              }
            });
          }
        }
        if (global.style=='f7') {
          table+="<td class='medium-only'>"+value+"</td>";
        }
        else {
          table+="<td>"+value+"</td>";
        }
      });

      if (typeof JSON.parse(v.val).note!='undefined') {
        if (global.style=='f7') {
          table+='<td><i title="'+JSON.parse(v.val).note+'" class="f7-icons">info_circle</i></td>';
        }
        else {
          table+='<td><i title="'+JSON.parse(v.val).note+'" class="fa fa-info-circle"></i></td>';
        }
      }
      else {
        table+='<td><i style="color:#ccc;">Nessuna nota</i></td>';
      }

      // table+="<td>"+dashIfIsNull(JSON.parse(v.val).note)+"</td>";
      if (global.style=='f7') {
        if (!storic) {
          table+='<td class="hideInPrint"><i date="'+v.date+'" class="modificaSurvey f7-icons">pencil_circle</i></td>';
          table+='<td class="hideInPrint"><i onclick="location.hash=\'/delete_survey/'+v.id_survey+'/schema/'+id_survey_schema+'/field/'+id_field+'\'" class="f7-icons">trash_circle</i></td>';
        }
        else {
          var disabledf7='disabled';
          if (canModify()) {
            disabledf7='';
          }
          table+='<td class="hideInPrint"><i onclick="location.hash=\'/data_entry/'+v.id_field+'/'+(location.hash.split('/')[3]).replace(/'/g, '’')+'/crop/'+location.hash.split('/')[5]+'/schema/'+v.id_survey_schema+'/data/'+v.date+'\'" class="f7-icons" '+disabledf7+'>pencil_circle</i></td>';
          table+='<td class="hideInPrint"><i onclick="location.hash=\'/delete_survey/'+v.id_survey+'/schema/'+id_survey_schema+'/field/'+id_field+'/storic\'" class="f7-icons" '+disabledf7+'>trash_circle</i></td>';
        }
      }
      else {
        if (!storic) {
          table+='<td class="hideInPrint"><button date="'+v.date+'" class="modificaSurvey btn btn-default">Modifica</button></td>';
          table+='<td class="hideInPrint"><button onclick="location.hash=\'/delete_survey/'+v.id_survey+'/schema/'+id_survey_schema+'/field/'+id_field+'\'" class="btn btn-default">Elimina</button></td>';
        }
        else {
          var disabled='disabled';
          if (canModify()) {
            disabled='';
          }
          table+='<td class="hideInPrint"><button onclick="location.hash=\'/data_entry/'+v.id_field+'/'+(location.hash.split('/')[3]).replace(/'/g, '’')+'/crop/'+location.hash.split('/')[5]+'/schema/'+v.id_survey_schema+'/data/'+v.date+'\'" class="btn btn-default" '+disabled+'>Modifica</button></td>';
          table+='<td class="hideInPrint"><button onclick="location.hash=\'/delete_survey/'+v.id_survey+'/schema/'+id_survey_schema+'/field/'+id_field+'/storic\'" class="btn btn-default" '+disabled+'>Elimina</button></td>';
        }
      }

      table+='</tr>';
    });
    table+='</tbody>';
    table+='</table>';
    if (method=='html') {
      jQuery('#'+div).html(table);
    }
    else {
      jQuery('#'+div).append(table);
    }
    jQuery(".modificaSurvey").click(function(){
      modificaSurvey(jQuery(this).attr('date'));
    });

    // Rende la tabella ordinabile ---------------
    var tt_dom = jQuery('#'+div + ' table')[0];
    sorttable.makeSortable(tt_dom);
  }
  else {
    var emptyTable='';
    if (storic) {
      emptyTable+='<div class="alert alert-warning description">';
        emptyTable+='Non ci sono dati disponibili per questa scheda.';
      emptyTable+='</div>';
    }

    if (method=='html') {
      jQuery('#'+div).html(emptyTable);
    }
    // Se si vuole nella stampa anche quando non ci sono dati scommentare qui sotto
    // else {
    //   jQuery('#'+div).append(table+emptyTable);
    // }
  }
}

function importAll(r) {
  return r.keys().map(r);
}

export function canModify(){
  //CONTROLLO SE POSSO MODIFICARE O NO

  return true;
}

export function mapClick(){
  global.map.on('click', function(e){
    var aLatLon={
      latitude:e.latlng.lat,
      longitude:e.latlng.lng
    };
    jQuery('#lat_azienda').val(e.latlng.lat);
    jQuery('#lon_azienda').val(e.latlng.lng);
    setMarker(aLatLon);
  });
}

export function printDataSelect(data,tipo){


  global.crop_data=data.crops.data;
  var opt="<option value='0' selected disabled>Seleziona la coltura</option>";
  jQuery.each(data.crops.data,function(k,v) {
    opt+="<option value='"+v.id_crop+"'>"+v.name+"</option>";
  });
  jQuery('#nome_coltura').html(opt);

  var opt_area="<option value='0' selected disabled>Seleziona l'area</option>";
  jQuery.each(data.area.data,function(k,v) {
    opt_area+="<option value='"+v.id_area+"'>"+v.nome_area+"</option>";
  });
  jQuery('#id_area').html(opt_area);



  if (tipo=='update') {
    console.log("***********************************");
    console.log(data);

    var opt_user='';
    if (jQuery.inArray( 'administrator', global.aRoles)>-1 || jQuery.inArray( 'adminmonit', global.aRoles)>-1) {
      jQuery.each(data.users.data,function(k,v) {
        opt_user+="<option value='"+v.id_user+"'>"+v.name+"</option>";
      });
      jQuery('#farm_center_user').html(opt_user);
    }

    console.log(global.idFarmCenterInEditing);


    var s=storage.get();
    var request={
      uid:global.uid,
      access_token:s.user.access_token,
      id_farm_center:global.idFarmCenterInEditing
    };

    var mon= new monitoring();
    var get_farm_center_and_field_data=mon.get_farm_center_and_field_data(request);

    get_farm_center_and_field_data
    .then( (data) => {
      console.log(data);
      printFarmCenterAndFieldData(data);
    })
    .catch( (err) => {
      console.log(err);
      alert("Err"+err);
    });
  }
}

export function checkFarmCenterData(){
  var OK=false;
  if (jQuery('#id_area').val()!=null) {
    if (jQuery('#indirizzo_azienda').val()!='') {
      if (jQuery('#neighbors_stations').val()!=null) {
        if (jQuery('#nome_azienda').val()!='') {
          //if (jQuery('#nome_coltura').val()!=null) {
            if (jQuery('#farm_method').val()!=null) {
              if (jQuery('#lat_azienda').val()!='') {
                if (jQuery('#lon_azienda').val()!='') {
                  OK=true;
                }
                else {
                  alert('Inserisci la longitudine per salvare');
                }
              }
              else {
                alert('Inserisci la latitudine per salvare');
              }
            }
            else {
              alert('Scegli il metodo di difesa per salvare');
            }
          // }
          // else {
          //   alert('Scegli la coltura per salvare');
          // }
        }
        else {
          alert('Inserisci il nome della tua azienda per salvare');
        }
      }
      else {
        alert('Scegli la stazione meteo di riferimento per salvare');
      }
    }
    else {
      alert('Inserisci la località per trovare le coordinate');
    }
  }
  else {
    alert("Inserisci l'area per salvare");
  }

  return OK;
}

export function setMarker(position){
  if (global.my_marker) {
    global.map.removeLayer(global.my_marker);
  }

  var mu='./'+marker_red;
  var su='./'+marker_shadow;

  var miaAzienda = L.icon({
    iconUrl: mu,
    shadowUrl: su,
    iconSize:       [25, 41],   // size of the icon
    shadowSize:     [25, 41],   // size of the shadow
    iconAnchor:     [12, 41],   // dove vai a posizionare l'icona rispetto al punto dove si trova
    shadowAnchor:   [6 , 41],    // dove vai a posizionare l'ombra rispetto al punto dove si trova
    popupAnchor:    [0,-41]
  });

  global.my_marker = L.marker([position.latitude,position.longitude]);
  global.my_marker.setIcon(miaAzienda);
  global.my_marker.addTo(global.map).bindPopup("Questa è la tua posizione.");
  global.map.setView([position.latitude,position.longitude]);

  commonJS.selectNeighborsStations(position);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function storeAziendaMonitoring(param,tipo,rapid,varData){

  if (typeof tipo=='undefined') {
    tipo='insert';
  }

  var s=storage.get();
  var request={
    uid:global.uid,
    access_token:s.user.access_token,
    lat:param.latitude,
    lon:param.longitude,
    name:param.farmCenterName,
    place:param.place,
    id_area:param.area,
    farm_method:param.farmMethod,
    id_station:param.id_station,
    comune:param.comune
  };

  if (param.elevation!='') {
    request.elevation=param.elevation;
  }

  if (tipo=='update') {
    if (jQuery.inArray( 'administrator', global.aRoles)>-1 || jQuery.inArray( 'adminmonit', global.aRoles)>-1) {
      request.id_user=param.id_user;
    }
    request.id_farm_center=global.idFarmCenterInEditing;
    request.id_crop=param.cropName;
    request.field=JSON.stringify(param.fields);
  }
  else {
    request.field=param.field;
  }

  var urladdr = global.base_call_aedita+"monitoring_webpack/upsert_farm_field/"+tipo;
  jQuery.ajax({
    type: 'GET',
    url: urladdr,
    data:request,
    dataType: "json",
    success: function(res){
      if (res.ok) {
        window.history.back();
        // location.hash="";
      }
      else {
        alert(res.message);
      }
      console.log(res);
    }
  });
}

export function printSelectData(data){
  var opt='';
  jQuery.each(data.crops.data,function(k,v) {
    var selected='';
    if (v.id_crop==2) {
      selected='selected';
    }
    opt+="<option value='"+v.id_crop+"' "+selected+">"+v.name+"</option>";
  });
  jQuery('#nome_coltura').html(opt);

  var opt_provincia='<option value="0" selected disabled>Seleziona la provincia</option>';
  jQuery.each(data.provincia.data,function(k,v) {
    var selected='';
    // if (v.id_crop==2) {
    //   selected='selected';
    // }
    opt_provincia+="<option value='"+v.name_3+"' "+selected+">"+v.name_3+"</option>";
  });
  jQuery('#provincia').html(opt_provincia);

  var opt_comune='<option value="0" selected disabled>Seleziona il comune</option>';
  jQuery.each(data.comuni.data,function(k,v) {
    var selected='';
    // if (v.id_crop==2) {
    //   selected='selected';
    // }
    opt_comune+="<option value='"+v.admin_code+"' "+selected+">"+v.name+"</option>";
  });
  jQuery('#comune').html(opt_comune);

  jQuery("#provincia").change(function() {
    var opt_filtered=data.comuni.data.filter(function(f){return (f.name_3==jQuery('#provincia').val());});
    var opt_comune='<option value="0" selected disabled>Seleziona il comune</option>';
    jQuery.each(opt_filtered,function(k,v) {
      opt_comune+="<option value='"+v.admin_code+"'>"+v.name+"</option>";
    });

    jQuery('#comune').html(opt_comune);
  });
}

export function printSchemasInMonitoring(schemas,id_field,id_survey_schema,monitoring_date,id_crop){
  console.log(id_survey_schema);
  console.log(monitoring_date);
  var schemas_var=schemas;
  var opt="<option value='0' selected disabled>Selezionare la scheda che vuoi inserire</option>";
  jQuery.each(schemas,function(k,v) {
    opt+="<option value='"+v.id_survey_schema+"'>"+v.name+"</option>";
  });
  jQuery('#schemaDataEntry').html(opt);

  jQuery('#monitoring_date, #schemaDataEntry').change(function() {
    var margin=jQuery('#dbmng_form').width()/2;
    jQuery('#dbmng_form').html(commonJS.loading(margin));

    var description='';
    var schemaInArray=schemas_var.filter(function(f){return (f.id_survey_schema==jQuery('#schemaDataEntry').val());});
    if (schemaInArray.length>0) {
      if (schemaInArray[0].description!=null) {
        description="<div class='alert alert-info'><b>Descrizione: </b>"+schemaInArray[0].description+"</div>";
      }
    }
    jQuery('#schemaDescription').html(description);

    if (jQuery('#monitoring_date').val()!='' && jQuery('#schemaDataEntry').val()!=null) {
      if (typeof id_field!=='undefined') {
        drawForm(jQuery('#schemaDataEntry').val(),id_field);
      }
      else {
        var s=storage.get();
        var request={
          uid:global.uid,
          access_token:s.user.access_token,
          anno_visualizzazione:jQuery('#anno_visualizzazione').val(),
          id_survey_schema:jQuery('#schemaDataEntry').val(),
          date_monitoring:jQuery('#monitoring_date').val(),
          id_crop:id_crop
        };


        var mon= new monitoring();
        var get_multi_form=mon.get_multi_form(request);

        get_multi_form
        .then( (data) => {
          // chiamata dbmng custom
          drawMultiForm(jQuery('#schemaDataEntry').val(),data,id_crop);
        })
        .catch( (err) => {
          console.log(err);
          alert("Err"+err);
        });

      }
    }
  });

  if (typeof id_survey_schema!='undefined' && typeof monitoring_date!='undefined') {
    jQuery('#monitoring_date').val((monitoring_date));
    jQuery('#schemaDataEntry').val(id_survey_schema);
    jQuery('#schemaDataEntry').trigger('change');
  }
}

export function drawMultiForm(id_survey_schema,data,id_crop){

  var fields=data.fields;

  fields=fields.data.filter(function(f){return f.id_crop==id_crop;});

  var voc_val_fields=[];
  jQuery.each(fields,function(k,v){
    var field_obj={};

    field_obj[v.id_field]=v.name;

    voc_val_fields.push(field_obj);
  });

  var dbmng_fields={};
  dbmng_fields.id_survey={label: 'ID', type: 'int', widget:"hidden", key: 1, 'nullable': false};
  dbmng_fields.id_field={label: 'Appezzamento', type: 'varchar',readonly:true,  widget: 'select', 'voc_val':voc_val_fields};

  jQuery.each(data.survey_var.data,function(k,v){
    if (v.widget=='numeric') {
      dbmng_fields[v.var_name]={label: capitalizeFirstLetter(v.name), type:'int' , widget:v.widget, 'default': null};
    }
    else if (v.widget=='select') {
      var selArray=[];
      var vars_selected=data.survey_code.data.filter(function(f){return (v.id_survey_var==f.id_survey_var);});

      var codes=data.survey_code.data;
      jQuery.each(codes,function(i,d) {
        var obj={};
        obj[d.id_survey_code]=d.name;
        selArray.push(obj);
      });

      dbmng_fields[v.var_name]={label: capitalizeFirstLetter(v.name), type: 'int', widget:v.widget, voc_val: selArray};
    }
    else if (v.widget=='date') {
      dbmng_fields[v.var_name]={label: capitalizeFirstLetter(v.name), type: 'date', widget:v.widget, date_format_view:'d MM, yy'};
    }
    else {
      alert('Widget non trovato.');
    }
  });

  var aForm_inline = {
    table_name: 'survey',
    primary_key: ['id_survey'],
    fields:dbmng_fields
  };

  //data selezionata
  var date_selected=jQuery('#monitoring_date').val();

  //tutte le aziende per la coltura prese da API

  //dati già inseriti
  var entered_data=data.survey.data;

  var dati_inline=[];

  jQuery.each(fields, function(k,f){

    //Trova se esiste per lid_field già dei dati
    var existing_data=entered_data.filter(function(v){return v.id_field==f.id_field;});
    if(existing_data.length>0){
      var values=JSON.parse(existing_data[0].val);
      existing_data[0] = jQuery.extend({}, existing_data[0], values);

      console.log(existing_data[0]);
      dati_inline.push(existing_data[0]);
    }
    else{
      console.log(f.id_field);
      dati_inline.push({id_field: f.id_field, date: date_selected});
    }
  });

  var form_inline = new Dbmng.FormInline({aForm: aForm_inline,aParam:{'hide_label': true}});
  jQuery('#dbmng_form').html(form_inline.createForm(dati_inline));

  var saveButton='<button id="salva" class="btn btn-success btn-block">Salva</button>';
  jQuery('#dbmng_form').append(saveButton);

  jQuery("#salva").click(function() {
    console.log(form_inline.getValue());
    global.goBack=false;
    global.doneGoBack=false;

    var obj={
      surveys: form_inline.getValue(),
      id_survey_schema:id_survey_schema,
      date: jQuery('#monitoring_date').val()
    };

    storeSurveys(obj);
  });
}

export function drawForm(id_survey_schema,id_field){
  global.currentYear=commonJS.getAnnoCorrente();

  var dataEntryForm = {
    table_name: 'ae_'+global.currentYear+'.survey_schema',
    primary_key: Array('id'),
    fields: {}
  };

  var s=storage.get();
  var request={
    uid:global.uid,
    access_token:s.user.access_token,
    id_survey_schema:id_survey_schema,
    id_field:id_field,
    date:jQuery('#monitoring_date').val()
  };

  var mon= new monitoring();
  var get_schemaVar=mon.get_schemaVar(request);

  get_schemaVar
  .then( (vars) => {
    printSchemaVar(vars,dataEntryForm,id_survey_schema,id_field);
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });
}

export function printSchemaVar(vars,dataEntryForm,id_survey_schema,id_field){
  var vars_base=vars;
  vars=vars.data.filter(function(f){return (f.id_survey_schema==id_survey_schema);});

  jQuery.each(vars,function(k,v) {
    if (v.widget=='numeric') {
      dataEntryForm.fields[v.var_name]={label: capitalizeFirstLetter(v.name), type:'int' , widget:v.widget, 'default': null};
    }
    else if (v.widget=='select') {
      var selArray=[];
      var vars_selected=vars_base.code.data.filter(function(f){return (v.id_survey_var==f.id_survey_var);});

      printSchemaCode({data:vars_selected},selArray,dataEntryForm,v);
    }
    else if (v.widget=='date') {
      dataEntryForm.fields[v.var_name]={label: capitalizeFirstLetter(v.name), type: 'date', widget:v.widget, date_format_view:'d MM, yy'};
    }
    else {
      console.log(vars);
      alert('Widget non trovato.');
    }

    var is_nullable=false;

    // debugger
    if(typeof v.nullable!=='undefined' && v.nullable!=null){
      if(v.nullable==1){
        // is_nullable=true;
        if (typeof dataEntryForm.fields[v.var_name]!='undefined') {
          dataEntryForm.fields[v.var_name].nullable=1;
        }
      }
      else {
        if (typeof dataEntryForm.fields[v.var_name]!='undefined') {
          dataEntryForm.fields[v.var_name].nullable=0;
        }
      }
    }
    if(is_nullable){
      if (typeof dataEntryForm.fields[v.var_name]!='undefined') {
        dataEntryForm.fields[v.var_name].nullable=1;
      }
    }
  });

  dataEntryForm.fields.note={label: capitalizeFirstLetter('Note'), type:'varchar' , widget:'text',nullable:1};

  var obj={};
  var buttonLabel='Inserisci';

  // console.log(vars_base.survey);
  var surveys=vars_base.survey;

  printSurveys(surveys,dataEntryForm,obj,id_field,buttonLabel,id_survey_schema);
}

export function printSchemaCode(codes,selArray,dataEntryForm,v){
  codes=codes.data;
  jQuery.each(codes,function(i,d) {
    var obj={};
    obj[d.id_survey_code]=d.name;
    selArray.push(obj);
  });

  dataEntryForm.fields[v.var_name]={label: capitalizeFirstLetter(v.name), type: 'int', widget:v.widget, voc_val: selArray};
}

export function dmy2ymd(date){
  if(typeof date !=='undefined'){
    var aDate=date.split('/');
    var today_selector_date=aDate[2]+"-"+aDate[1]+"-"+aDate[0];
    return today_selector_date;
  }
  else{
    return date;
  }
}

export function eliminaAzienda(id_farm_center){
  var c = confirm("Sei sicuro di voler eliminare l'azienda?");
  if (c) {
    var s=storage.get();
    var request={
      uid:global.uid,
      access_token:s.user.access_token,
      id_farm_center:id_farm_center
    };

    var urladdr = global.base_call_aedita+"monitoring_webpack/deleteFarmCenter";
    jQuery.ajax({
    type: 'GET',
    data:request,
    url: urladdr,
    dataType: "json",
      success: function(deleted){
        console.log(deleted);
        if (deleted.ok) {
          window.history.back();
          // location.hash="";
        }
        else {
          if (deleted.message.substring(0,15)=='SQLSTATE[23503]') {
            alert("Non è possibile eliminare l'azienda poiché ci sono altri dati che andrebbero persi.");
            location.hash="";
          }
        }
      }
    });
  }
}

export function printSurveys(surveys_,dataEntryForm,obj,id_field,buttonLabel,id_survey_schema){


  var surveys=JSON.parse(JSON.stringify(surveys_));
  console.log(surveys);
  surveys.data=surveys.data.filter(function(f){return (f.id_survey_schema+""==id_survey_schema && f.id_field+""==id_field && f.date==jQuery('#monitoring_date').val());});
  surveys=surveys.data;


  if (surveys.length>0) {
    console.log(surveys[0].val);
    obj=JSON.parse(surveys[0].val);
    buttonLabel='Aggiorna';
  }
  else {
    jQuery.each(dataEntryForm.fields,function(k,v){
      if (v.widget=='numeric') {
        console.log(v);
        obj[k]=0;
      }
    });
  }

  var dataEntry=new Dbmng.Form({aForm:dataEntryForm, aParam:{}});
  jQuery('#dbmng_form').html('<div class="block-title block-title-medium">Inserimento dati</div>');
  jQuery('#dbmng_form').append(dataEntry.createForm(obj));
  var html='';

  if (global.style=='f7') {
    if (id_field!=-1) {
      html+='<div class="col-xs-6" style="width: 100%;">';
        html +="<a style='margin-right:5px; height:30px;' id='insertNewSurvey' class='item-link link external item-content button button-fill'>"+buttonLabel+"</a>";
      html +="</div>";
      html+='<div class="col-xs-6" style="width: 100%;">';
        html +="<a style='margin-right:5px; height:30px;' id='insertNewSurveyMore' class='item-link link external item-content button button-fill'>"+buttonLabel+" e continua</a>";
      html +="</div>";

    }
    else {
      html+='<div class="col-xs-12" style="width: 100%;">';
        html +="<a style='margin-right:5px; height:30px;' id='insertNewSurvey' class='item-link link external item-content button button-fill'>"+buttonLabel+"</a>";
      html +="</div>";
    }

    jQuery('#footer').html(html);
  }
  else {
    html +="<div class='row'>";
    if (id_field!=-1) {
      html +="<div class='col-sm-6'>";
        html +="<button id='insertNewSurvey' class='btn btn-success separator-top btn-block'>"+buttonLabel+"</button>";
      html +="</div>";
      html +="<div class='col-sm-6'>";
        html +="<button id='insertNewSurveyMore' class='btn btn-success separator-top btn-block'>"+buttonLabel+" e continua</button>";
      html +="</div>";
    }
    else {
      html +="<div class='col-sm-12'>";
        html +="<button id='insertNewSurvey' class='btn btn-success separator-top btn-block'>"+buttonLabel+"</button>";
      html +="</div>";
    }
    html +="</div>";

    jQuery('#dbmng_form').append(html);
  }



  jQuery('form').on('focus', 'input[type=number]', function (e) {
    jQuery(this).on('wheel.disableScroll', function (e) {
      e.preventDefault();
    });
  });
  jQuery('form').on('blur', 'input[type=number]', function (e) {
    jQuery(this).off('wheel.disableScroll');
  });


  getStoricTable('storicTable',id_survey_schema,id_field);

  jQuery('#insertNewSurvey,#insertNewSurveyMore').click(function() {

    //TODO Skip corrected
    //var isCorrect=checkDataEntry().ok;
    //isCorrect=true;
    // debugger
    if (id_field!=-1) {
      var isCorrect=dataEntry.isValid();
      if (isCorrect.ok) {

        var varData=dataEntry.getValue();
        var date=jQuery('#monitoring_date').val();
        var id=jQuery(this).attr('id');
        if (id=='insertNewSurveyMore') {
          storeSurvey(id_survey_schema,id_field,date,varData,'more');
        }
        else {
          storeSurvey(id_survey_schema,id_field,date,varData);
        }
      }
      else {
        jQuery('.error_message').html("Dato obbligatorio");
      }
    }
    else {
      var param={
        farmCenterName:jQuery('#nome_azienda').val(),
        comune:jQuery('#comune').val(),
        place:capitalizeFirstLetter(jQuery('#indirizzo_azienda').val()),
        latitude:jQuery('#lat_azienda').val(),
        longitude:jQuery('#lon_azienda').val(),
        cropName:jQuery('#nome_coltura').val()
      };
      storeAziendaMonitoring(param,'insert','rapid',dataEntry.getValue());
    }
  });
}


export function getCoordinatesFromLocation(location){
  var urladdr = 'https://nominatim.openstreetmap.org/search?format=json&q='+location+', '+global.nomeRegione;
  jQuery.ajax({
    type: 'POST',
    url: urladdr,
    dataType: "jsonp",
    jsonp:'json_callback',
    success: function(osm){
      if(osm.length>0){
        var my_position = {'latitude':osm[0].lat, 'longitude':osm[0].lon};

        setMarker(my_position);

        jQuery('#lat_azienda').val(my_position.latitude);
        jQuery('#lon_azienda').val(my_position.longitude);
        return my_position;
      }
      else{
        alert('Località non trovata.');
      }
    }
  });
}


export function printFarmCenterAndFieldData(data){

  if(data.ok){
      if(data.farm_center.data.length>0){

        var farmCenter=data.farm_center.data[0];

        console.log(farmCenter);
        var point={latitude:farmCenter.lat,longitude:farmCenter.lon};
        commonJS.createMap('map',point);
        mapClick();

        var lastStation=farmCenter.id_station;
        setMarker({latitude:farmCenter.lat,longitude:farmCenter.lon});

        jQuery('#nome_azienda').val(farmCenter.name);
        jQuery('#id_area').val(farmCenter.id_area);
        jQuery('#farm_method').val(farmCenter.farm_method);
        jQuery('#elevation').val(farmCenter.elevation);
        jQuery('#lat_azienda').val(farmCenter.lat);
        jQuery('#lon_azienda').val(farmCenter.lon);
        jQuery('#indirizzo_azienda').val(farmCenter.place);

        jQuery('#farm_center_user').val(farmCenter.id_user);



        var field_data=data.field.data;

        var crop_array={};
        jQuery.each(global.crop_data, function(k,v){
          crop_array[v.id_crop]=v.name;
        });

        console.log(crop_array);
        var aFormF = { table_name: 'field',
          primary_key: Array('id_field'),
          fields: {
            id_field: {label: 'id_field', widget: 'hidden', key:1}, //key=1
            name: {label:'Nome del campo', type: 'varchar', nullable:0},
            // id_crop: {label:'Coltura', type: 'varchar', nullable:0},
            id_crop: {label: 'Coltura', type: 'int', widget:'select', voc_val:crop_array},
            cultivar: {label:'Cultivar', type: 'varchar', nullable:0}
            // provincia: {label:'Provincia', type: 'varchar', nullable:1}
          }
        };
        var formF=new Dbmng.FormInline({aForm:aFormF, aParam:{'hide_label': true, 'do_insert': true,'do_delete': true}});
        //salvo la variabile globale
        global.formField=formF;
        jQuery('#fields_container').append(formF.createForm(field_data));


      }
      else{
        alert("Non ci sono farm_center associati");
      }
  }
  else{
    alert(data.message);
  }

}

export function printFarmCenter(farmCenters,manipulationType){
  if (manipulationType=='edit') {
    location.hash='/edit_farm_center/'+id_farm_center;
  }

  if(!farmCenters.ok){
    jQuery('#monitoring_content').html(farmCenters.message);
  }
  else{
    farmCenters=farmCenters.data;

    var html="";
    if (farmCenters.length>0) {

      html += "<div class='row separator'>";
        html += "<div class='col-sm-12 row'>";
          html += "<div id='myFarmCenters' style='height:450px;' class='col-sm-4'></div>";
          html += "<div id='fieldsTableDIV' class='col-sm-8'>";
            html += "<div id='fieldsTable' class='table-responsive'></div>";
            html += "<div id='addButton'></div>";
          html+="</div>";
        html += "</div>";
      html += "</div>";
      jQuery('#monitoring_content').html(html);

      commonJS.createMap('myFarmCenters');
      markerMyFarmCenters(farmCenters);
      showFields('fieldsTable');
      // console.log(storage.get());

      html='';

      if (typeof inserimenti_multi!='undefined' && inserimenti_multi==true) {
        html+='<button id="dati_multipli" class="btn btn-default">Inserisci dati multipli</button>';
      }

      if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
        // Levo temporaneamente la possibilità di aggiungere l'azienda, questo il perché degli if 'incoerenti' all'interno
        if (global.style!=='f7') {

          html+='<div id="addAziendaButton">';
          if (global.style=='f7') {
            html+='<div class="f7-icons addAziendaIcon f7-style">plus</div>';
          }
          else {
            html+='<div class="fa fa-plus addAziendaIcon">';
            html+='</div>';
          }
          html+='</div>';

          html+='<div class="addAzienda">Aggiungi azienda</div>';
          jQuery('#addButton').html(html);
        }
      }

      jQuery("#dati_multipli").click(function() {
        location.hash='data_entry/generic';
      });

      jQuery("#addAziendaButton").hover(
        function(){
          jQuery(this).addClass('addAziendaIcon-hover');
        },
        function(){
          jQuery(this).removeClass('addAziendaIcon-hover');
        }
      );

      console.log(storage.get());
    }
    else {
      if (jQuery('#anno_visualizzazione').val()==global.anno_corrente) {
        html+="<button id='aggiungi_azienda' class='btn btn-info btn-block'>Aggiungi la tua prima azienda</button>";
        jQuery('#monitoring_content').html(html);
      }
    }

    jQuery('#aggiungi_azienda,#addAziendaButton').click(function() {
      location.hash="/aggiungi_azienda";
    });
  }
}

export function storeSurveys(surveys){
  var s=storage.get();

  surveys.uid=global.uid;
  surveys.user.access_token=s.user.access_token;

  var mon= new monitoring();

  var stringify=JSON.stringify(surveys);

  var store_survey=mon.store_survey({"data":stringify});

  store_survey
  .then( (insertedSurvey) => {
    if (typeof global.doneGoBack!='undefined') {
      if (!global.doneGoBack) {
        global.doneGoBack=true;
        window.history.back();
        window.history.back();
      }
    }
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });

}

export function storeSurvey(id_survey_schema,id_field,date,varData,continua){
  var s=storage.get();
  var request={
    uid:global.uid,
    access_token:s.user.access_token,
    id_field:id_field,
    date:date,
    varData:varData,
    id_survey_schema:id_survey_schema
  };

  var mon= new monitoring();
  var store_survey=mon.store_survey(request);

  store_survey
  .then( (insertedSurvey) => {
    printStoreSurvey(insertedSurvey,continua);
  })
  .catch( (err) => {
    console.log(err);
    alert("Err"+err);
  });
}

export function printStoreSurvey(insertedSurvey,continua){
  if (typeof continua !='undefined') {
    var lastHash=location.hash;
    var lastSelectedOption=jQuery('#schemaDataEntry').val();

    jQuery('#schemaDataEntry').val(lastSelectedOption);
    jQuery('#schemaDataEntry option:selected').next().attr('selected', 'selected');
    jQuery('#schemaDataEntry').trigger('change');
  }
  else {
    window.history.back();
  }
}

export function modificaSurvey(dataR){
  jQuery('#monitoring_date').val((dataR));
  jQuery('#monitoring_date').trigger('change');
}
