/*jshint esversion: 6 */
import monitoring from "./data.js";
import * as f from "./aedita.js";
import * as commonJS from "./commonJS.js";
import './style.css';

import global from '../../global.js';
import storage from '../../storage.js';
import gps from '../../core/gps.js';
import t from '../../translate.js';
import mapHelper from '../../core/map.js';

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************

class aedita {
  constructor(opt) {
    console.log(global);
    console.log(opt);
    global.aedita_opt=opt;
    global.style=opt.style;
    var theme=new Dbmng.BootstrapTheme();
    if (opt.style=='f7') {
      theme=new Dbmng.Framework7Theme();
    }

    Dbmng.defaults.theme=theme;
  }

  test() {
    return "ABRACADABRA";
  }


  //COME FARE FUNZIONARE LA COSa
  //cancellare il json
  //eseguire monitoraggio_minimalaaa
  //eseguire monitoraggio_minimal quelo vero

  info_utili(){
    var urladdr = global.base_call_aedita+"monitoring_webpack/get_info_utili";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      dataType: "json",
      success: function(data){
        console.log(data);

        var html='';
        html+='<div class="data-table">';
          html+='<table>';
            html+='<thead>';
              html+='<tr>';
                html+='<th>Titolo</th>';
              html+='</tr>';
            html+='</thead>';
            html+='<tbody id="farm_list">';
            jQuery.each(data,function(k,v){
              if (v.link) {
                html+='<tr onclick="window.location=\''+v.link+'\'">';
                  html+='<td>'+v.title+'</td>';
                html+='</tr>';
              }
              else {
                html+='<tr onclick="window.location=\''+'https://agroambiente.regione.abruzzo.it/'+'node/'+v.node_id+'\'">';
                  html+='<td>'+v.title+'</td>';
                html+='</tr>';
              }
            });

            html+='</tbody>';
          html+='</table>';
        html+='</div>';

        var page={
          'title': "Informazioni utili",
          'content': html,
          'exclude_card':true
        };
        global.theme.render(page);

      },
      error: function(e){
        console.log(e);
      },
    });
  }


  libreria(){
    var self=this;

    var html='';

    html+=`
    <div class="rodw" id="sel_data">
      <div class="cdol-33 list" >
        <ul>
          <li>
            <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
              <select id="coltura">
                <option value="all">Tutte le colture</option>
                <option value="Vite">Vite</option>
                <option value="Olivo">Olivo</option>
                <option value="Cereali">Cereali</option>
                <option value="Generica">Generica</option>
                <option value="Ortive">Ortive</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">Coltura</div>
                  <div class="item-after"></div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
              <select id="argomento">
                <option value="all">Tutti gli argomenti</option>
                <option value="Tecniche agronomiche">Tecniche agronomiche</option>
                <option value="Difesa">Difesa</option>
                <option value="Fenologia">Fenologia</option>
                <option value="AgroMeteorologia">AgroMeteorologia</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">Argomento</div>
                  <div class="item-after"></div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    `;
    html+='<div id="libreria">';
    html+='</div>';

    var page={
      'title': "Libreria",
      'content': html,
      'exclude_card':true
    };
    global.theme.render(page);

    jQuery('#coltura,#argomento').change(function(){

      var request={};

      if (jQuery('#coltura').val()!=='all') {
        request.coltura=jQuery('#coltura').val();
      }

      if (jQuery('#argomento').val()!=='all') {
        request.argomento=jQuery('#argomento').val();
      }

      self.getLibreria(request);

    }).change();

  }

  getLibreria(request){
    var urladdr = global.base_call_aedita+"monitoring_webpack/get_libreria";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      data:request,
      dataType: "json",
      success: function(data){
        console.log(data);

        var html='';
        html+='<div class="data-table">';
          html+='<table>';
            html+='<thead>';
              html+='<tr>';
                html+='<th>Coltura</th>';
                html+='<th>Argomento</th>';
                html+='<th>Titolo</th>';
              html+='</tr>';
            html+='</thead>';
            html+='<tbody id="farm_list">';
            jQuery.each(data,function(k,v){
              html+='<tr onclick="window.location=\''+global.base_link+'node/'+v.node_id+'\'">';
                if (v.field_coltura.und) {
                  html+='<td style="text-align:center;">'+v.field_coltura.und[0].value+'</td>';
                }
                else {
                  html+='<td style="text-align:center;">'+"Tutto"+'</td>';
                }

                if (v.field_argomento.und) {
                  html+='<td style="text-align:center;">'+v.field_argomento.und[0].value+'</td>';
                }
                else {
                  html+='<td style="text-align:center;">'+"---"+'</td>';
                }
                html+='<td>'+v.title+'</td>';
              html+='</tr>';
            });
            html+='</tbody>';
          html+='</table>';
        html+='</div>';
        jQuery('#libreria').html(html);
      },
      error: function(e){
        console.log(e);
      },
    });
  }

  bollettini(tipo){
    console.log("TIPO",tipo);
    var html='';


    html+='<div class="row no-gap">';
      if (tipo=='agrometeorologia') {
        html+='<div class="col">';
          html+='<div class="card">';
            html+='<div class="card-content card-content-padding card-bollettini" id="first-agrometeo">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
        html+='<div class="col">';
          html+='<div class="card">';
            html+='<div class="card-content card-content-padding card-bollettini" id="second-agrometeo">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      }
      else if (tipo=='difesa') {
        // html+='<div class="col">';
        //   html+='<div class="card">';
        //     html+='<div class="card-content card-content-padding card-bollettini" id="first-fitosanitario">';
        //     html+='</div>';
        //   html+='</div>';
        // html+='</div>';
        html+='<div class="col">';
          html+='<div class="card">';
            html+='<div class="card-content card-content-padding card-bollettini" id="agrofenologia">';
            html+='</div>';
          html+='</div>';
        html+='</div>';

        html+='<div class="col">';
          html+='<div class="card">';
            html+='<div class="card-content card-content-padding card-bollettini" id="difesa-integrata">';
            html+='</div>';
          html+='</div>';
        html+='</div>';

      }
    html+='</div>';


    html+='<div id="bollettini">';
    html+='</div>';

    var page={
      'title': tipo.toUpperCase()+"<br>"+"Bollettini regionali",
      'content': html,
      'exclude_card':true
    };
    global.theme.render(page);

    var urladdr = global.base_call_aedita+"monitoring_webpack/get_bollettini";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      data:{
        tipo:tipo
      },
      dataType: "json",
      success: function(data){
        console.log(data);

        var html='';

        html+='<div class="block-title">Archivio storico dei bollettini</div>';

        html+='<div class="data-table">';
          html+='<table>';
            html+='<thead>';
              html+='<tr>';
                html+='<th>Tipo</th>';
                html+='<th>Data</th>';
                html+='<th>Titolo</th>';
              html+='</tr>';
            html+='</thead>';
            html+='<tbody id="farm_list">';


            var used_first_agrometeo=false;
            var used_second_agrometeo=false;
            var used_first_fitosanitario=false;
            var used_second_fitosanitario=false;

            var html_agrometeo='';
            var html_2_agrometeo='';
            var html_fitosanitario='';
            var html_agrofenologia='';


            jQuery.each(data,function(k,v){
              var d = new Date(v.field_date.und[0].value);
              var date=d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();

              var tipo='---';
              var tipologia_var=null;
              if (v.field_tipo && v.field_tipo.und) {
                if (v.field_tipo.und[0].value.substr(11)=='Fitosanitario') {
                  tipo='<i class="f7-icons" style="padding-top: 6px;">ant</i>';
                  tipo+=v.field_tipo.und[0].value.substr(11)+'</td>';
                  tipologia_var=v.field_tipo.und[0].value.substr(11);
                }
                else if (v.field_tipo.und[0].value.substr(11)=='AgroMeteo') {
                  tipo='<i class="f7-icons" style="padding-top: 6px;">cloud_sun</i>';
                  tipo+=v.field_tipo.und[0].value.substr(11)+'</td>';
                  tipologia_var=v.field_tipo.und[0].value.substr(11);
                }
                else if (v.field_tipo.und[0].value=='AgroFenologia') {
                  tipo='<i class="f7-icons" style="padding-top: 6px;">tree</i>';
                  tipo+=v.field_tipo.und[0].value+'</td>';
                  tipologia_var=v.field_tipo.und[0].value;
                }
                else if (v.field_tipo.und[0].value=='Difesa integrata') {
                  tipo='<i class="f7-icons" style="padding-top: 6px;">xmark_shield_fill</i>';
                  tipo+=v.field_tipo.und[0].value+'</td>';
                  tipologia_var=v.field_tipo.und[0].value;
                }

                console.log(tipologia_var);
              }

              if (!used_first_agrometeo&&tipologia_var=='AgroMeteo') {
                used_first_agrometeo=true;
                html_agrometeo+=tipo;
                html_agrometeo+='<br><br><b>'+v.title+'</b>';
                html_agrometeo+='<br><br>del '+date;
                jQuery('#first-agrometeo').html(html_agrometeo).click(function() {
                   window.location=global.base_link+'node/'+v.node_id;
                });
              }
              else if (used_first_agrometeo&&!used_second_agrometeo&&tipologia_var=='AgroMeteo') {
                used_second_agrometeo=true;
                html_2_agrometeo+=tipo;
                html_2_agrometeo+='<br><br><b>'+v.title+'</b>';
                html_2_agrometeo+='<br><br>del '+date;
                jQuery('#second-agrometeo').html(html_2_agrometeo).click(function() {
                   window.location=global.base_link+'node/'+v.node_id;
                });
              }
              else if (!used_first_fitosanitario&&tipologia_var=='Difesa integrata') {
                used_first_fitosanitario=true;
                html_fitosanitario+=tipo;
                html_fitosanitario+='<br><br><b>'+v.title+'</b>';
                html_fitosanitario+='<br><br>del '+date;
                jQuery('#difesa-integrata').html(html_fitosanitario).click(function() {
                   window.location=global.base_link+'node/'+v.node_id;
                });
              }
              else if (!used_second_fitosanitario&&tipologia_var=='AgroFenologia') {
                used_second_fitosanitario=true;
                html_agrofenologia+=tipo;
                html_agrofenologia+='<br><br><b>'+v.title+'</b>';
                html_agrofenologia+='<br><br>del '+date;
                jQuery('#agrofenologia').html(html_agrofenologia).click(function() {
                   window.location=global.base_link+'node/'+v.node_id;
                });
              }
              else {
                var timestamp=parseInt(v.sent_time);
                if (typeof v['google.sent_time']!='undefined') {
                  timestamp=v['google.sent_time'];
                }

                html+='<tr onclick="window.location=\''+global.base_link+'node/'+v.node_id+'\'">';
                  html+='<td style="text-align:center;">';

                  html+=tipo;

                  html+='<td>'+date+'</td>';
                  html+='<td>'+v.title+'</td>';
                html+='</tr>';
              }

            });
            html+='</tbody>';
          html+='</table>';
        html+='</div>';
        jQuery('#bollettini').html(html);

      },
      error: function(e){
        console.log(e);
      },
    });
  }

  monitoraggio(user_id,manipulationType,id_farm_center){
    console.log(global);
    if (global.style=='f7') {
      console.log(global.loadingText);
      global.app.dialog.preloader(global.loadingText);
    }

    console.log(user_id);
    var s=storage.get();
    var user=s.user;
    global.aRoles=[];
    if (global.style!=='f7') {
      adminMonitHashChange();
    }
    jQuery.each(s.user.roles,function(k,v){
      global.aRoles.push(v);
    });

    var html='';


    html+="<select class='hide_in_mobile' id='anno_visualizzazione'></select>";
    html+="<div id='monitoring_content'></div>";


    if (global.style=='f7') {
      html+="<div id='storic_table_div'></div>";
    }
    var page={
      'title': "Monitoraggio <span id='fieldName'></span>",
      'content': html,
      'exclude_card':true
    };

    global.anno_corrente=commonJS.getAnnoCorrente();

    console.log("prima di render");
    global.theme.render(page);

    var opt='';
    opt+="<option id='"+global.anno_corrente+"' selected>"+global.anno_corrente+"</option>";
    opt+="<option id='"+(global.anno_corrente-1)+"'>"+(global.anno_corrente-1)+"</option>";
    jQuery('#anno_visualizzazione').html(opt);



    jQuery('#anno_visualizzazione').change(function() {


      var s=storage.get();
      console.log(s);

      global.uid=s.user.uid;

      var request={
        uid:global.uid,
        access_token:s.user.access_token,
        anno_visualizzazione:jQuery('#anno_visualizzazione').val()
      };

      console.log(request);
      var mon= new monitoring();
        if (global.style=='f7') {
          global.app.dialog.close();
        }

      //chiamata con la promise che cancellava il jstorage
      //INIZIO CODICE COMMENTATO
      var get_farm_center=mon.get_farm_center(request)
      .then( (farmCenters) => {
        f.printFarmCenter(farmCenters,manipulationType);
        console.log(storage.get());
        if (global.style=='f7') {
          global.app.dialog.close();
        }
      })
      .catch( (err) => {
        console.log(err);
        alert("Err"+err);
        // if (global.style=='f7') {
        //   global.app.dialog.close();
        // }
      });
      //FINE CODICE COMMENTATO

      // console.log(storage.get());
    });


    jQuery('#anno_visualizzazione').change();
  }


  visualizzazioneDati(year){
    var self=this;
    var s=storage.get();

    var map=new mapHelper();
    var request={};
    if (year) {
      request.year=year;
    }
    var urladdr = global.base_call_aedita+"mod_aedita_services/get_combined";
    jQuery.ajax({
      type: 'POST',
      url: urladdr,
      data:request,
      dataType: "json",
      success: function(data){
        var opt_survey_schemas='';
        jQuery.each(data.survey_schema,function(k,v){
          opt_survey_schemas+=`<option value="${v.id_survey_schema}">${v.name}</option>`;
        });

        var content=`

        <div class="rodw" id="sel_data">
          <div class="cdol-33 list" >
            <ul>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="id_survey_schema"> ${opt_survey_schemas} </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Scheda</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="id_survey_var"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Variabile</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="week"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Data</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>

              <li>
                <a class="item-link smart-select smart-select-init" data-open-in="sheet"  data-close-on-select="true">
                  <select id="year"></select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Anno</div>
                      <div class="item-after"></div>
                    </div>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
        <div id="agroMap">${map.getHtml(false)}<div>
        <div class="map_legend"></div>
        `;



        var page={
          exclude_card:true,
          title: "Visualizzazione",
          content: content
        };

        global.theme.render(page);

        var opt_year='';
        for (var i = new Date().getFullYear(); i >= 2020; i--) {
          var selected='';
          if (year==i) {
            selected='selected';
          }
          opt_year+=`<option value="${i}" ${selected}>${i}</option>`;
        }
        jQuery('#year').html(opt_year);//.change();,
        jQuery('#year').parent().find('.item-after').text(jQuery("#year option:selected").text());


        jQuery('#year').change(function(){
          self.visualizzazioneDati(jQuery(this).val());
        });

        jQuery('#agroMap').parent().css('margin',0);
        jQuery('#agroMap #map_container').css('height',outerHeight/2.2);

        jQuery('#runGPS').parent().remove();

        jQuery("#manual-Map-checkbox input").change(function() {
          jQuery('#manual-Map-checkbox').css('opacity','0.5');
          jQuery(this).attr("disabled", true);
          gps.manualMap();

          jQuery('#manual_gps_latitude').change(function(){
            // self.getTiles({
            //   bufferedLatitude:parseFloat(jQuery('#manual_gps_latitude').val()),
            //   bufferedLongitude:parseFloat(jQuery('#manual_gps_longitude').val())
            // });
          });
        });



        var point={latitude:43.32517767999296,longitude:11.744384765625002};

        if (global.nomeRegione=='Abruzzo') {
          // Abruzzo
          point={latitude:42.5,longitude:14};
        }

        commonJS.createMap('map_container',point,8);


        self.runGPS();


        jQuery("#sel_data select").on("change", function(){
          if (jQuery(this).attr('id')!='year') {
            var id_changed=jQuery(this).attr("id");
            var id_survey_schema=jQuery("#id_survey_schema").val();

            if(id_changed=='id_survey_schema'){
              var opt_survey_vars='';
              var opt_weeks='';

              jQuery.each(data.survey_schema,function(k,v){
                if (v.id_survey_schema==id_survey_schema) {
                  jQuery.each(v.survey_var,function(j,sv){
                    console.log(sv);
                    opt_survey_vars+=`<option value="${sv.var_name}">${sv.name}</option>`;
                  });

                  jQuery.each(v.week,function(i,w){
                    console.log(w);
                    opt_weeks+=`<option value="${w.week_number}">${w.week}</option>`;
                  });

                }
              });

              console.log(opt_survey_vars);
              console.log(opt_weeks);


              jQuery('#week').html(opt_weeks);//.change();
              jQuery('#week').parent().find('.item-after').text(jQuery("#week option:selected").text());

              jQuery('#id_survey_var').html(opt_survey_vars);//.change();
              jQuery('#id_survey_var').parent().find('.item-after').text(jQuery("#id_survey_var option:selected").text());

              //update id_survey_var e week
            }

            var id_survey_var=jQuery("#id_survey_var").val();
            var week=jQuery("#week").val();
            var year=jQuery("#year").val();

            self.viewData(id_survey_schema, id_survey_var, week, year);
          }
        });

        jQuery("#id_survey_schema").change();
        jQuery('#id_survey_schema').parent().find('.item-after').text(jQuery("#id_survey_schema option:selected").text());


      },
      'error': function(err){
        global.app.dialog.close();
      }
    });

  }

  isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
  }
  //Vedi un singolo dato su mappa
  viewData(id_survey_schema, id_survey_var, week, year){
    // debugger
    console.log(year);

    var self=this;
    console.log(id_survey_schema,id_survey_var,week);

    // Ripulisco la mappa dai vecchi marker
    jQuery('.leaflet-ab-circle').remove();

    var urladdr = global.base_call_aedita+"mod_aedita_services/get_combined_data/"+id_survey_schema+"/"+week;
    jQuery.ajax({
      type: 'POST',
      url: urladdr,
      data:{
        year:year
      },
      dataType: "json",
      success: function(data){
        console.log(data);
        if (data.survey.data.length>0) {

          var styles=null;
          if (data.survey_schema.survey_var[0].json && data.survey_schema.survey_var[0].json!==null) {
            styles=JSON.parse(data.survey_schema.survey_var[0].json);
          }

          jQuery.each(data.survey.data,function(k,v){
            // debugger
            var color='blue';
            var realColor='';
            try {
              var cat=JSON.parse(v.val);
              console.log(data);

              var value_survey=null;
              if (isNaN(parseFloat(cat[jQuery('#id_survey_var').val()]))) {
                value_survey=(cat[jQuery('#id_survey_var').val()]);
              }
              else {
                value_survey=parseFloat(cat[jQuery('#id_survey_var').val()]);
              }

              color=self.getCellColor(styles,v, value_survey).toUpperCase();
              realColor=color;

              var extension_class='';
              if (color=='#FFFFFF') {
                color='white';
              }
              else if(color=='#4CAF50') {
                color='green';
              }
              else if(color=='#FFEB3B') {
                color='yellow';
              }
              else if(color=='#F44336') {
                color='red';
              }
              else {
                extension_class='custom_color';
              }

            }
            catch (e) {
              console.log(v.val);
              console.log(e);
            }


            var myIcon = L.divIcon({
              iconSize: new L.Point(20, 20),
              className: 'leaflet-ab-circle data_from_'+v.tipo+' '+extension_class+' leaflet-div-icon-circle-'+color
            });


            console.log("--------------");
            console.log(v,v.val);
            console.log("--------------");

            L.marker([v.lat,v.lon], {icon: myIcon}).addTo(global.map).bindPopup(typeof(v.val)=='string'?v.val.replace(/[\{\}"]/g,''):v.val);

            jQuery('.custom_color').css('background-color',realColor);
          });





          var style=styles.style;
          var legend="";
          var color;
          var value_in_legend;

          jQuery.each(style, function(k,v){
            // debugger
            if (typeof v.value!='undefined' && v.value+""!=='NaN') {
              if (typeof v.value=='string') {
                value_in_legend=v.value;
                color=v.color;

                if (typeof v.label!='undefined') {
                  value_in_legend=v.label;
                }
              }
              else {
                value_in_legend=v.value.toFixed(2);
                color=v.color;

                //debugger
                if (typeof v.label!='undefined') {
                  value_in_legend=v.label;
                }
                else {
                  jQuery.each(data.survey_schema.survey_var,function(j,t) {
                    // debugger
                    // if (t.widget=='select') {
                      jQuery.each(data.survey_schema.survey_code,function(i,d) {
                        if (d.id_survey_code==value_in_legend) {
                          value_in_legend=d.name;
                        }
                      });
                    // }
                  });
                }
              }

              legend+='<li id="legend_value_'+v.value+'" style="'+style+'"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="'+color+'"/></svg>'+value_in_legend+'</li>';
            }
          });


          if(legend===''){
            legend+="Non ci sono punti";
          }
          else {
            legend+=`<li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="#2196f3"/></svg>Dati non validi</li>`;

          }

          jQuery('.map_legend').html('<ul>'+legend+'</ul>');



          // var legend=`
          //     <li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><rect width="20" height="20" stroke="black" fill="#2196f3"/></svg>Dati monitoraggio</li>
          //     <li id="legend_value_circle"><svg style="margin-right: 10px;" height="20" width="20"><circle cx="10" cy="10" r="10" stroke="black" fill="#2196f3"/></svg>Dati partecipativi</li>
          // `;
          //
          // jQuery('.map_legend').html('<ul>'+legend+'</ul>');
        }

        global.app.dialog.close();
      },
      'error': function(err){
        global.app.dialog.close();
      }
    });


  }

  //Produci il colore della cella in funzione degli stili styles e del v (field)
  getCellColor(styles, v, value){
    var self=this;
    // debugger
      var color='';
      if (styles!==null && !self.isEmpty(styles)) {

        // console.log(v.widget);
          if(typeof styles.style!=='undefined'){
            jQuery.each(styles.style,function(i,d){
              try{
                var valore=parseFloat(value);
                var minimo=d.value;
                var massimo=minimo;
                if((i+1)<(styles.style).length){
                  massimo=styles.style[i+1].value;
                }
                else {
                  if(valore>= minimo){
                    color=d.color;
                  }
                }
                if(valore>= minimo && valore<massimo){
                  color=d.color;
                }
              }
              catch(e){console.log(e);}
              if (d.value==value) {
                color=d.color;
                return false;
              }
            });
          }
      }
      console.log(color);
      return color;
  }

  runGPS(){
    var self=this;
    gps.startGPS({
      getFasterPosition:true,
      save_place: true, //dentro la libreria chiama il reverse geocode e lo salva in global.coordinates
      buffer_max_size: 22222222222,
      position: function(pos){
        // debugger
        if (global.blocked_pos!==true) {
          console.log("KLEAN",pos);
          jQuery('#localizationIcon').html('<i class="f7-icons">location_fill</i>');


          // if (global.is_cordova()) {
            //   pos=JSON.parse(pos);
            // }
            pos.timestamp=new Date().getTime();
            global.coordinates=pos;

            console.log(pos);

            setTimeout(function(){
              global.marker = L.marker([pos.bufferedLatitude,pos.bufferedLongitude]).addTo(global.map);
            },1000);
            console.log(pos);

            global.map.panTo([pos.bufferedLatitude,pos.bufferedLongitude],{animate:true});
            // self.getTiles(pos);
        }
        else {
          console.log("posizione bloccata...");
        }

      }
    });

  }

  eliminaSurvey(id_survey,id_survey_schema,id_field,className){

    var s=storage.get();
    var request={
      id_survey:id_survey,
      access_token:s.user.access_token
    };

    if(commonJS.is_cordova()){
      request.id_survey_schema=id_survey_schema;
      request.id_field=id_field;
    }

    var mon= new monitoring();
    var delete_survey=mon.delete_survey(request);

    delete_survey
    .then( (deleted) => {
      if (deleted.ok) {
        location.hash='#monitoring';
      }
      else {
        alert(JSON.stringify(deleted));
      }
    })
    .catch( (err) => {
      console.log(err);
      alert("Err"+err);
    });
  }

  fixHeight(selector){
    var maxh=0;
    jQuery(selector).each(function(k,v){maxh=Math.max(maxh,(jQuery(v).height()));});
    jQuery(selector).height(maxh);
  }

  getTiles(pos, layer){
    var col='50';
    // debugger
    if (layer) {
      col='100';
      var page={
        exclude_card:true,
        title: global.capitalizeFirstLetter(layer.replaceAll(/_/g, ' ')),
        content: `<div id="data_content"></div>`
      };

      global.theme.render(page);
    }


    if(!layer){
      layer='all';
    }

    var self=this;
    jQuery('#data_content').html("Sto cercando le informazioni...");

    var urladdr = global.base_call_aedita+"mod_aedita_services/get_tiles";
    jQuery.ajax({
      type: 'POST',
      data:{
        lat:pos.bufferedLatitude,
        lon:pos.bufferedLongitude,
        layer:layer,
        date:commonJS.getTodayDate()
      },
      url: urladdr,
      dataType: "json",
      success: function(data){
        console.log("GET TILES",data.records);
        // debugger
        var html='';

        if (data.records.length>0 && data.mode!='mosca') {
          html+='<div class="row">';
          jQuery.each(data.records,function(k,v){
            html+=`<div class="col-${v.layer=='cereali'?'100':col} ${v.layer=='cereali'?'':'tile-to-fix'} tile">`;
              var color='white';
              var textColor='black';
              var hexColor=null;
              if (v.color=='verde') {
                color='green';
                // textColor='white';
              }
              else if (v.color=='rosso') {
                color='red';
                textColor='white';
              }
              else if (v.color=='giallo') {
                color='yellow';
              }
              else if (v.color=='blu') {
                // color='blue';
                hexColor="#51ABF6";
              }



              var styleColor='';
              if (hexColor!=null) {
                styleColor="background-color:"+hexColor+"!important";
              }

              var onclick='';
              if(v.layer && v.layer!='undefined'){
                onclick=`location.hash='tile/${pos.bufferedLatitude}/${pos.bufferedLongitude}/${v.layer}'`;
              }
              
              html+=`<div onclick="${onclick && onclick!=''?onclick:''}" class="card card-outline bg-color-${color}" style="color:${textColor}; ${styleColor};">`; //background-color:'+color+';
                html+='<div class="card-header">'+v.layer_label+'</div>';

                html+=`
                      <div class="card-content card-content-padding" style="${v.layer=='cereali'?'background-color:white;':''}">
                        ${v.status}
                        <br>
                        <small>${v.layer=='cereali'?self.generateHtmlFrumento(v,v.agroambiente):v.layer_description}</small>
                      </div>`;

              html+='</div>';

            html+='</div>';
          });
          html+='</div>';

        }
        else if(data.mode=='mosca' && Object.keys(data.records).length>0){
          html+='<div class="row">';
          jQuery.each(data.records,function(k,v){
            // check that not is the last element
            if(k!='az'){
              html+=`<div class="col-${v.layer=='cereali'?'100':col} ${v.layer=='cereali'?'':'tile-to-fix'} tile">`;
                var color='white';
                var textColor='black';
                var hexColor=null;
                if (v.color=='verde') {
                  color='green';
                  // textColor='white';
                }
                else if (v.color=='rosso') {
                  color='red';
                  textColor='white';
                }
                else if (v.color=='giallo') {
                  color='yellow';
                }
                else if (v.color=='blu') {
                  // color='blue';
                  hexColor="#51ABF6";
                }



                var styleColor='';
                if (hexColor!=null) {
                  styleColor="background-color:"+hexColor+"!important";
                }

                  var onclick='';
                  if(v.layer && v.layer!='undefined'){
                    onclick=`location.hash='tile/${pos.bufferedLatitude}/${pos.bufferedLongitude}/${v.layer}'`;
                  }
                  
                  html+=`<div onclick="${onclick && onclick!=''?onclick:''}" class="card card-outline bg-color-${color}" style="color:${textColor}; ${styleColor};">`; //background-color:'+color+';

                // html+=`<div onclick="location.hash='tile/${pos.bufferedLatitude}/${pos.bufferedLongitude}/${v.layer}'" class="card card-outline bg-color-${color}" style="color:${textColor}; ${styleColor};">`; //background-color:'+color+';
                  html+='<div class="card-header">'+(typeof(v.layer_label)!='undefined'?v.layer_label:v.nome_azienda)+'</div>';
                  html+=`
                        <div class="card-content card-content-padding">
                          ${v.status?v.status:('Totale: '+(v.totale*100)+'% - Attiva: '+(v.attiva*100)+'%')}
                          <br>
                          <small>${v.layer_description?v.layer_description:v.max_date}</small>
                        </div>`;

                html+='</div>';

              html+='</div>';
            }
          });
          html+='</div>';

        }
        else {
          html+="<div class='block-title' style='margin-top: 0;'><i>Non ci sono dati disponibili.</i></div>";
        }

        jQuery('#data_content').html(html);

        jQuery('.card-expandable').height(jQuery('.card-expandable').width());
        jQuery('.descP').css('width',(jQuery('.card-expandable').width()-10));
        global.app.dialog.close();

        self.fixHeight('.tile-to-fix>.card');
      },
      'error': function(err){
        global.app.dialog.close();
      }
    });
  }

  generateHtmlFrumento(rec,data){
    var self=this;
    console.log(rec,data);
    var precoce=0;
    var media=1;
    var tardiva=2;


    window.tipoFrumentoChange=self.tipoFrumentoChange;
    // var id_farm_center=rec.nearest_farm.

    // debugger
    var popup = "";
    if(!data.frumento_matrixes){
      popup+="<div>Non ci sono dati</div>";
    }
    else if(typeof data.frumento_matrixes[rec.nearest_farm.id_farm_center]=='undefined'){
      popup+="<div>Non ci sono dati in questa settimana</div>";

    }
    else{

      // popup+='<ul id="tipo_frumento" class="nav nav-tabs">';
      //   popup+='<li class="nav-item">';
      //     popup+='<a id="Duro_a" onClick="tipoFrumentoChange(\'Duro\')" class="tipo_frumento_a nav-link active">Duro</a>';
      //   popup+="</li>";
      //   popup+='<li class="nav-item">';
      //     popup+='<a id="Tenero_a" onClick="tipoFrumentoChange(\'Tenero\')" class="tipo_frumento_a nav-link">Tenero</a>';
      //   popup+="</li>";
      // popup+="</ul>";
      popup+="<div style='background-color:white;'>";

      popup+=`

      <p class="segmented segmented-strong">
        <button id="Duro_a" onClick="tipoFrumentoChange(\'Duro\')" class="tipo_frumento_a button button-active">Duro</button>
        <button id="Tenero_a" onClick="tipoFrumentoChange(\'Tenero\')" class="tipo_frumento_a button">Tenero</button>
        <span class="segmented-highlight"></span>
      </p>
      `;

      var tipo=['Duro','Tenero'];

      jQuery.each(tipo,function(j,l){
        var display="";
        if (l=="Tenero") {
          display="display:none;";
        }
        popup+="<div style='background-color:white; "+display+"' id='"+l+"_div'>";
          popup+='<div class="data-table">';

          popup+="<table>";
            popup+="<tr>";
              popup+="<th>Scheda</th>";
              popup+="<th>Precoci</th>";
              popup+="<th>Medie</th>";
              popup+="<th>Tardive</th>";
            popup+="</tr>";

            var feno=[];

            jQuery.each(data.frumento_matrixes && data.frumento_matrixes[rec.nearest_farm.id_farm_center].matrix[l],function(k,v){

              if (k!='other') {
                var kLabel=self.giveKLabel(k);

                if (k=='fenologi') {
                  popup+='<tr><td colspan="4"></td></tr>';
                }

                popup+="<tr>";
                  popup+="<th>"+kLabel+"</td>";
                  jQuery.each(v,function(i,d){
                    var backgroundColor='white';

                    if (d.order_n==0) {
                      backgroundColor='#81C784';
                    }
                    else if (d.order_n>0 && d.order_n<=5) {
                      backgroundColor='#FFF176';
                    }
                    else if (d.order_n>5 && d.order_n<=15) {
                      backgroundColor='#FFB74D';
                    }
                    else if (d.order_n>15) {
                      backgroundColor='#E57373';
                    }

                    console.log(d.order_n+' - '+backgroundColor);
                    if (k=='fenologi') {
                      backgroundColor='white';
                      feno.push(d.label);
                    }

                    popup+="<td style='background-color:"+backgroundColor+";'>"+d.label+"</td>";
                  });
                popup+="</tr>";

                console.log(v);
              }
            });


            var pioggia=false;
            try{
              var forecast={};
              jQuery.each(data.forecast.data,function(fk,f){
                if(f.id_area==rec.id_area){
                    forecast=JSON.parse(f.json_lamma);
                }
              });

              console.log("FORECATS",forecast);
              jQuery.each(forecast.dati.previsione, function(fk, f){
                console.log(f.prob_rain);
                if(f.simbolo && f.simbolo.image_name && f.simbolo.image_name.indexOf("pioggia")>=0){
                  console.log("FORECATS",f.simbolo.image_name);
                  pioggia=true;
                }
              });

            }
            catch(e){
              console.log("Error in forecat parse!!!");
            }

            var fusa=[];
            jQuery.each(feno,function(k,f){
              var label="Basso";
              var ff=false;
              if(f.indexOf("Fioritura")!==-1){
                ff=true;
              }

              if(ff){
                if(pioggia){
                  fusa.push('<span class="alert-danger" title="Fase fenologica a rischio di infezione; sono previste piogge nei prossimi giorni">Rischio alto</span>');
                }
                else{
                  fusa.push('<span class="alert-warning" title="Fase fenologica a rischio di infezione ma non si prevedono piogge nei prossimi giorni">Rischio basso</span>');
                }
              }
              else{
                fusa.push('<span class="alert-success" title="La Fase fenologica non è a rischio di infezione">Nessun rischio</span>');
              }
            });

            popup+="<tr>";
              popup+="<th>Rischio fusariosi</th>";
              popup+="<td id='fus_"+l+"_p'>"+fusa[0]+"</td>";
              popup+="<td id='fus_"+l+"_m'>"+fusa[1]+"</td>";
              popup+="<td id='fus_"+l+"_t'>"+fusa[2]+"</td>";
            popup+="</tr>";


          popup+="</table>";

          popup+='</div>';

          popup+="</div>";
      });
      popup+="</div>";
    }

    // popup+="<div id='forecast_container'>Sto caricando le previsioni..</div>";
    return popup;

    // return "";
  }

  giveKLabel(k){
    var kLabel='';
    if (k=='gialla') {
      kLabel="Ruggine gialla";
    }
    else if (k=='bruna') {
      kLabel="Ruggine bruna";
    }
    else {
      kLabel=global.capitalizeFirstLetter(k);
    }
    return kLabel;
  }

  tipoFrumentoChange(tipo){
    jQuery('.tipo_frumento_a').removeClass('button-active');
    jQuery('#'+tipo+'_a').addClass('button-active');

    jQuery('#Duro_div').hide();
    jQuery('#Tenero_div').hide();

    jQuery('#'+tipo+'_div').show();
  }

  openDataEntry(id_crop, id_field,fieldName,varieta, id_survey_schema,monitoring_date,type){
    global.today=commonJS.getTodayDate();

    global.todayDMY=commonJS.addZero(new Date(global.today).getDate())+"/"+commonJS.addZero(new Date(global.today).getMonth()+1)+"/"+commonJS.addZero(new Date(global.today).getFullYear());
    // debugger
    var html="";

      if (global.style=='f7') {
        var back='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
        jQuery('.left').html(back);
        html+='<form class="list" id="my-form">';
          html+='<ul>';
            html+='<li>';
              html+='<div class="item-content item-input">';
                html+='<div class="item-inner">';
                  html+='<div class="item-title item-label">Data di monitoraggio</div>';
                  html+='<div class="item-input-wrap">';
                    html+='<input id="monitoring_date" type="date" name="name" data-date="" data-date-format="DD MMMM YYYY" value="'+global.today+'" placeholder="Data di monitoraggio"></input>';
                  html+='</div>';
                html+='</div>';
              html+='</div>';
            html+='</li>';
      }
      else {
        html+="<div class='col-sm-12'>";
          html+="<p><button class='btn btn-default' id='back'><span class='fa fa-reply'></span> Torna indietro</button></p>";
          html+="<label for='monitoring_date'>Data di monitoraggio</label>";
          html+="<input type='text' class='form-control' type='date' name='name' data-date='' data-date-format='DD MMMM YYYY'  value='"+global.today+"' id='monitoring_date'></input>";
        html+="</div>";
      }

    if (typeof type!='undefined' && type=='rapid') {

      html +="<div class='col-sm-12'>";
        html +="<label>Nome azienda</label>";
        html +="<input id='nome_azienda' class='form-control' placeholder='Inserisci il nome della tua azienda'></input>";
      html +="</div>";

      html +="<div class='col-sm-12'>";
        html +="<label>Provincia</label>";
        html +="<select id='provincia' class='form-control'></select>";
      html +="</div>";

      html +="<div class='col-sm-12'>";
        html +="<label>Comune</label>";
        html +="<select id='comune' class='form-control'></select>";
      html +="</div>";

      html +="<div class='col-sm-12'>";
        html +="<label>Inserisci località</label>";
        html+="<input type='input' id='indirizzo_azienda' class='form-control'></input>";
      html +="</div>";

      html +="<div class='hide col-sm-12'>";
        html +="<label>Latitudine</label>";
        html+="<input type='input' id='lat_azienda' class='form-control' value='0'></input>";
      html +="</div>";

      html +="<div class='hide col-sm-12'>";
        html +="<label>Longitudine</label>";
        html+="<input type='input' id='lon_azienda' class='form-control' value='0'></input>";
      html +="</div>";

      html +="<div class='col-sm-12'>";
        html +="<label>Nome coltura</label>";
        html +="<select id='nome_coltura' class='form-control'></select>";
      html +="</div>";

    }
    if (typeof id_crop=='undefined') {
      html+="<div class='col-sm-12'>";
        html+="<label for='nome_coltura'>Seleziona la coltura</label>";
        html+="<select class='form-control' id='nome_coltura'></select>";
      html+="</div>";

      html+="<div class='col-sm-12'>";
        html+="<button class='btn btn-default' id='select_crop'>Avanti</button>";
      html+="</div>";

      jQuery('#monitoring_content').html(html);

      jQuery("#select_crop").click(function() {
        location.hash="data_entry/crop/"+jQuery('#nome_coltura').val();
      });

      var s=storage.get();
      var request={
        uid:global.uid,
        access_token:s.user.access_token
      };

      var mon= new monitoring();
      var get_select_data=mon.get_select_data(request);

      get_select_data
      .then( (data) => {
        f.printSelectData(data);
      })
      .catch( (err) => {
        console.log(err);
        alert("Err"+err);
      });


    }
    else {
      if (global.style=='f7') {
            html+='<li>';
              html+='<div class="item-content item-input">';
                html+='<div class="item-inner">';
                  html+='<div class="item-title item-label">Selezionare la scheda che vuoi inserire</div>';
                  html+='<div class="item-input-wrap">';
                    html+='<select id="schemaDataEntry" placeholder="Selezionare la scheda che vuoi inserire"></select>';
                  html+='</div>';
                html+='</div>';
              html+='</div>';
            html+='</li>';
          html+='</ul>';
        html+='</form>';
      }
      else {
        html +="<div class='col-sm-12'>";
          html +="<label>Selezionare la scheda che vuoi inserire</label>";
          html +="<select id='schemaDataEntry' class='form-control'></select>";
          html +="<div id='schemaDescription' class='description'></div>";
        html +="</div>";
      }

      html +="<div id='dbmng_form' class='col-sm-12'></div>";
      jQuery('#monitoring_content').html(html);

      if (global.style=='f7') {
        var storic_table_f7="<div id='storicTable' class='data-table'></div>";
        jQuery('#storic_table_div').append(storic_table_f7);
      }
      else {
        var storic_table="<div id='storicTable' class='col-sm-12 table-responsive'></div>";
        jQuery('#monitoring_content').append(storic_table);
      }

      var s1=storage.get();
      var request1={
        uid:global.uid,
        access_token:s1.user.access_token
      };

      var mon1= new monitoring();
      var get_select_data1=mon1.get_select_data(request1);

      get_select_data1
      .then( (data) => {
        f.printSelectData(data);
      })
      .catch( (err) => {
        console.log(err);
        alert("Err"+err);
      });

      jQuery('#back').click(function() {
        window.history.back();
      });

      try{
        jQuery('#fieldName').html(" - "+(fieldName).replace(/%20/g, ' ').replace(/%E2%80%99/g, "'"));
        if (global.style!=='f7') {
          if (typeof varieta!='undefined') {
            if (varieta!='') {
              jQuery('#fieldName').append(" - "+(varieta).replace(/%20/g, ' ').replace(/%E2%80%99/g, "'"));//Risolvo il nome che mi è cambiato quando metto nell'url spazio o apostrofo
            }
          }
        }
        jQuery('#anno_visualizzazione').hide();
      }
      catch(e){
        console.log(e);
      }

      var schemas_var=null;

      var req={
        access_token:s1.user.access_token,
        id_crop:id_crop
      };

      var get_schemas=mon1.get_schemas(req);

      get_schemas
      .then( (schemas) => {
        f.printSchemasInMonitoring(schemas,id_field,id_survey_schema,monitoring_date,id_crop);
      })
      .catch( (err) => {
        console.log(err);
        alert("Err"+err);
      });
    }
  }

  getStoricTable(div, id_survey_schema,id_field,className,method){
    if (typeof method!='undefined') {
      method=method;
    }
    else {
      method='html';
    }


    var storic=false;
    if (typeof className!='undefined') {
      if (className=='storic') {
        storic=true;
      }
    }

    var s=storage.get();
    var input_data={
      id_survey_schema:id_survey_schema,
      id_field:id_field,
      access_token:s.user.access_token,
      anno_visualizzazione:jQuery('#anno_visualizzazione').val()
    };

    var mon= new monitoring();
    var get_storic_survey=mon.get_storic_survey(input_data);

    get_storic_survey
    .then( (storicSurveys) => {
      f.printStoricTable(storicSurveys,method,storic,id_survey_schema,id_field,div);
    })
    .catch( (err) => {
      console.log(err);
      alert("Err"+err);
    });
  }

  showStoricData(id_field,id_crop,fieldName,varieta){

    var html="";
    html+="<div class='col-sm-12'>";
      html+="<p><button class='btn btn-default' id='back'><span class='fa fa-reply'></span> Torna indietro</button>";
      if (!commonJS.is_cordova()) {
        html+="<button class='btn btn-default' id='print'><span class='fa fa-print'></span> Stampa</button></p>";
      }
    html+="</div>";
    html +="<div class='col-sm-12'>";
      html+='<div id="schmasTab"></div>';
    html +="</div>";

    html +="<div id='printStoricTable' style='display:none;' class='col-sm-12 table-responsive'></div>";

    html +="<div id='storicTable' class='col-sm-12 table-responsive'></div>";

    jQuery('#monitoring_content').html(html);

    jQuery('#back').click(function() {
      // history.go(-2);
      location.href=global.farm_home_path;
      // location.hash='';
    });

    jQuery("#print").click(function() {
      window.print();
    });

    jQuery('#fieldName').html(" - "+(fieldName).replace(/%20/g, ' ').replace(/%E2%80%99/g, "'"));//Risolvo il nome che mi è cambiato quando metto nell'url spazio o apostrofo
    // if (global.style!=='f7') {
      if (varieta!='') {
        jQuery('#fieldName').append(" - "+(varieta).replace(/%20/g, ' ').replace(/%E2%80%99/g, "'"));//Risolvo il nome che mi è cambiato quando metto nell'url spazio o apostrofo
      }
    // }
    jQuery('#anno_visualizzazione').hide();

    var s=storage.get();
    var request={
      uid:global.uid,
      access_token:s.user.access_token,
      id_crop:id_crop
    };

    var mon= new monitoring();
    var get_schemas=mon.get_schemas(request);

    get_schemas
    .then( (schemas) => {
      f.printSchemasStoric(schemas,id_field,fieldName,id_crop);
    })
    .catch( (err) => {
      console.log(err);
      alert("Err"+err);
    });
  }

  creaAzienda(tipo){

    var html="";
    if (typeof tipo=='undefined') {
      tipo='insert';
    }

    if (global.style=='f7') {
      html +="<div class='row'>";

        html +="<div class='col-sm-12'>";
          html+="<div id='map' style='height:450px;'></div>";
        html +="</div>";

        html +="<div class='col-sm-8'>";

          html+="<h3>Dati dell'azienda</h3>";

          html+='<form class="list form-store-data" id="my-form">';
            html+='<ul>';
              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Area</div>';
                    html+='<div class="item-input-wrap">';
                      html +="<select id='id_area'></select>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Inserisci la località</div>';
                    html+='<div class="item-input-wrap">';
                      html+="<input type='text' id='indirizzo_azienda' placeholder='Inserisci la località'></input>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Stazione meteo di riferimento:</div>';
                    html+='<div class="item-input-wrap">';
                      html+="<select id='neighbors_stations'></select>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Inserisci il nome della tua azienda</div>';
                    html+='<div class="item-input-wrap">';
                      html +="<input type='text' id='nome_azienda' placeholder='Inserisci il nome della tua azienda'></input>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Seleziona il metodo di difesa</div>';
                    html+='<div class="item-input-wrap">';
                      html +="<select id='farm_method'>";
                        html +="<option value='0' selected disabled>Seleziona il metodo di difesa</option>";
                        html +="<option value='bio'>Biologico</option>";
                        html +="<option value='integrato'>Integrato</option>";
                        html +="<option value='convenzionale'>Convenzionale</option>";
                      html +="</select>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Latitudine</div>';
                    html+='<div class="item-input-wrap">';
                      html+="<input type='text' id='lat_azienda'></input>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Longitudine</div>';
                    html+='<div class="item-input-wrap">';
                      html+="<input type='text' id='lon_azienda'></input>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';

              html+='<li>';
                html+='<div class="item-content item-input">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title item-label">Quota sul livello del mare</div>';
                    html+='<div class="item-input-wrap">';
                      html+="<input type='number' id='elevation'></input>";
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';


            html+='</ul>';
          html+='</form>';


          if (tipo=='insert') {

            html+="<h3>Dati dell'appezzamento</h3>";
            html+='<form class="list form-store-data" id="my-form">';
              html+='<ul>';
                html+='<li>';
                  html+='<div class="item-content item-input">';
                    html+='<div class="item-inner">';
                      html+='<div class="item-title item-label">Nome coltura</div>';
                      html+='<div class="item-input-wrap">';
                        html +="<select id='nome_coltura'></select>";
                      html+='</div>';
                    html+='</div>';
                  html+='</div>';
                html+='</li>';

                html+='<li>';
                  html+='<div class="item-content item-input">';
                    html+='<div class="item-inner">';
                      html+='<div class="item-title item-label">Cultivar</div>';
                      html+='<div class="item-input-wrap">';
                        html +="<input type='text' id='cultivar' placeholder='Inserisci la varietà o la cultivar..'></input>";
                      html+='</div>';
                    html+='</div>';
                  html+='</div>';
                html+='</li>';

              html+='</ul>';
            html+='</div>';

          }
          else{
            if (jQuery.inArray( 'administrator', global.aRoles)>-1 || jQuery.inArray( 'adminmonit', global.aRoles)>-1) {

              html+='<form class="list form-store-data" id="my-form">';
                html+='<ul>';
                  html+='<li>';
                    html+='<div class="item-content item-input">';
                      html+='<div class="item-inner">';
                        html+='<div class="item-title item-label">Utente legato al farm center</div>';
                        html+='<div class="item-input-wrap">';
                          html+="<select id='farm_center_user' class='form-control'></select>";
                        html+='</div>';
                      html+='</div>';
                    html+='</div>';
                  html+='</li>';
                html+='</ul>';
              html+='</div>';

            }

            html+="<h3>Elenco degli Appezzamenti</h3><div id='fields_container'></div>";
          }


          var button='<div class="col-xs-6" style="width: 100%;">';
            button +="<a style='margin-right:5px; height:30px;' id='saveStationMonitoring' class='item-link link external item-content button button-fill color-green'>Salva</a>";
          button +="</div>";

          if (tipo=='update') {
            button+='<div class="col-xs-6" style="width: 100%;">';
              button +="<a style='margin-right:5px; height:30px;' id='deleteStationMonitoring' class='item-link link external item-content button button-fill color-red'>Elimina l'azienda</a>";
            button +="</div>";
          }

          jQuery('#footer').html(button);

          // html+="<div class='col-sm-12'>";
          //   html+="<button class='separator-top btn btn-success btn-block' id='saveStationMonitoring'>Salva</button>";
          // html+="</div>";
          //
          // if (tipo=='update') {
          //   html+="<div class='col-sm-12'>";
          //     html+="<button class='separator-top btn btn-danger btn-block' id='deleteStationMonitoring'>Elimina l'azienda</button>";
          //   html+="</div>";
          // }


        html +="</div>";

      html +="</div>";

    }
    else {
      html+="<div class='col-sm-12' style='margin-bottom:20px;'>";
        html+="<p><button class='btn btn-default' id='back'><span class='fa fa-reply'></span> Torna indietro</button></p>";
      html+="</div>";

      html +="<div class='row'>";

        html +="<div class='col-sm-4'>";
          html+="<div id='map' style='height:450px;'></div>";
        html +="</div>";

        html +="<div class='col-sm-8'>";

          html+="<div class='col-sm-12'><h3>Dati dell'azienda</h3></div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Area</label>";
            html +="<select id='id_area' class='form-control'></select>";
          html +="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Inserisci località</label>";
            html+="<input type='input' id='indirizzo_azienda' class='form-control'></input>";
          html +="</div>";

          html+="<div class='col-sm-12'>";
            html+="<label for='neighbors_stations'>Stazione meteo di riferimento:</label>";
            html+="<select id='neighbors_stations' class='form-control'></select>";
          html+="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Nome azienda</label>";
            html +="<input id='nome_azienda' class='form-control' placeholder='Inserisci il nome della tua azienda'></input>";
          html +="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Metodo di difesa</label>";
            html +="<select id='farm_method' class='form-control'>";
              html +="<option value='0' selected disabled>Seleziona il metodo di difesa</option>";
              html +="<option value='bio'>Biologico</option>";
              html +="<option value='integrato'>Integrato</option>";
              html +="<option value='convenzionale'>Convenzionale</option>";
            html +="</select>";
          html +="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Latitudine</label>";
            html+="<input type='input' id='lat_azienda' class='form-control'></input>";
          html +="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Longitudine</label>";
            html+="<input type='input' id='lon_azienda' class='form-control'></input>";
          html +="</div>";

          html +="<div class='col-sm-12'>";
            html +="<label>Quota sul livello del mare</label>";
            html+="<input type='number' id='elevation' class='form-control'></input>";
          html +="</div>";

          if (tipo=='insert') {

            html+="<div class='col-sm-12'><h3>Dati dell'Appezzamento</h3></div>";
            html +="<div class='col-sm-12'>";
              html +="<label>Nome coltura</label>";
              html +="<select id='nome_coltura' class='form-control'></select>";
            html +="</div>";

            html +="<div class='col-sm-12'>";
              html +="<label>Cultivar</label>";
              html +="<input type='text' id='cultivar' class='form-control' placeholder='Inserisci la varietà o la cultivar..'></input>";
            html +="</div>";
          }
          else{
            if (jQuery.inArray( 'administrator', global.aRoles)>-1 || jQuery.inArray( 'adminmonit', global.aRoles)>-1) {
              html +="<div class='col-sm-12'>";
                html +="<label>Utente legato al farm center</label>";
                html+="<select id='farm_center_user' class='form-control'></select>";
              html +="</div>";
            }

            html+="<div class='col-sm-12'><h3>Elenco degli Appezzamenti</h3><div id='fields_container'></div></div>";
          }

          html+="<div class='col-sm-12'>";
            html+="<button class='separator-top btn btn-success btn-block' id='saveStationMonitoring'>Salva</button>";
          html+="</div>";

          if (tipo=='update') {
            html+="<div class='col-sm-12'>";
              html+="<button class='separator-top btn btn-danger btn-block' id='deleteStationMonitoring'>Elimina l'azienda</button>";
            html+="</div>";
          }

        html +="</div>";

      html +="</div>";

    }

    jQuery('#monitoring_content').html(html);

    jQuery('#back').click(function() {
      window.history.back();
      // location.hash='';
    });

    jQuery("#indirizzo_azienda").unbind().change(function() {
      f.getCoordinatesFromLocation(jQuery('#indirizzo_azienda').val());
    });

    jQuery("#deleteStationMonitoring").click(function() {
      eliminaAzienda(location.hash.split('/')[2]);
    });

    jQuery('#lat_azienda,#lon_azienda').change(function() {
      var point={
        latitude:jQuery('#lat_azienda').val(),
        longitude:jQuery('#lon_azienda').val()
      };
      setMarker(point);
    });

    jQuery('#saveStationMonitoring').click(function(){
      var check=f.checkFarmCenterData();
      if (check) {
        var point={};
        var id_station=jQuery('#neighbors_stations').val();

        var param={
          id_station:jQuery('#neighbors_stations').val(),
          farmCenterName:jQuery('#nome_azienda').val(),
          area:jQuery('#id_area').val(),
          farmMethod:jQuery('#farm_method').val(),
          elevation:jQuery('#elevation').val(),
          latitude:jQuery('#lat_azienda').val(),
          longitude:jQuery('#lon_azienda').val(),
          place:f.capitalizeFirstLetter(jQuery('#indirizzo_azienda').val())
        };

        if (tipo=='update') {
          if (jQuery.inArray( 'administrator', global.aRoles)>-1 || jQuery.inArray( 'adminmonit', global.aRoles)>-1) {
            param.id_user=jQuery('#farm_center_user').val();
          }

          console.log(global.formField.getValue());
          param.fields=global.formField.getValue();
          // console.log("CCC");
          // console.log(param.fields);
        }
        else {
          param.field={
            id_crop:jQuery('#nome_coltura').val(),
            cultivar:jQuery('#cultivar').val(),
          };
        }

        f.storeAziendaMonitoring(param,tipo);
      }
    });

    if (tipo=='insert') {
      //TODO le coordinate fanno prese da config!!!!
      var point={latitude:43.32517767999296,longitude:11.744384765625002};

      if (global.nomeRegione=='Toscana') {
        // Toscana
        point={latitude:43.32517767999296,longitude:11.744384765625002};
      }
      else if (global.nomeRegione=='Abruzzo') {
        // Abruzzo
        point={latitude:42.5,longitude:14};
      }

      commonJS.createMap('map_container',point,8);
      f.mapClick();
    }
    if(global.map){
      global.map.zoomOut();
    }

    var request={};

    // if (commonJS.is_cordova()) {
      var s=storage.get();
      request.access_token=s.user.access_token;
    // }

    var mon= new monitoring();
    var get_select_data=mon.get_select_data(request);

    get_select_data
    .then( (data) => {
      f.printDataSelect(data,tipo);
    })
    .catch( (err) => {
      console.log(err);
      alert("Err"+err);
    });
  }


}

export default (aedita);
