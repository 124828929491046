/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';

import * as commonJS from "./commonJS.js";

export default class monitoring {

  get_farm_center(request){
    return get_promise({
      'path':'monitoring_webpack/farm_center4user',
      'table_name':'farm_center'
    }, request);
  }

  get_fields(request){
    return get_promise({
      'path':'monitoring_webpack/fields4user',
      'table_name':'fields'
    }, request);
  }

  get_multi_form(request){
    return get_promise({
      'path':'monitoring_webpack/multi_form',
      'table_name':'fields'
    }, request);
  }

  get_survey(request){
    return get_promise({
      'path':'monitoring_webpack/getSurveys',
      'table_name':'survey'
    }, request);
  }

  get_schemaCode(request){
    return get_promise({
      'path':'monitoring_webpack/get_schemaCode',
      'table_name':'schema_code'
    }, request);
  }


  get_schemaVar(request){
    return get_promise({
      'path':'monitoring_webpack/get_schemaVar',
      'table_name':'schema_var'
    }, request);
  }

  get_select_data(request){
    if(!commonJS.is_cordova()){
      console.log(request);
      return new Promise(function (resolve, reject) {
        var urladdr = global.base_call_aedita+"monitoring_webpack/get_select_data";
        jQuery.ajax({
          type: 'GET',
          data:request,
          url: urladdr,
          dataType: "json",
          success: function(data){
            resolve(data);
          },
          'error': function(err){
            reject(err);
          }
        });
      });
    }
    else{
      return new Promise(function(resolve,reject){
        var statico=storage.get();
        if(typeof statico.select_data!=='undefined'){
          resolve(statico.select_data);
        }
        else{
          reject('No select data available');
        }
      });
    }
  }

  get_farm_center_and_field_data(request){
    return get_promise({
      'path':'monitoring_webpack/get_farm_center_and_field_data',
      'table_name':'farm_center_and_field_data'
    }, request);
  }

  get_schemas(request){
    if(!commonJS.is_cordova()){
      return new Promise(function (resolve, reject) {
        var urladdr = global.base_call_aedita+"monitoring_webpack/getSchemas";
        jQuery.ajax({
          type: 'GET',
          data:request,
          url: urladdr,
          dataType: "json",
          success: function(data){
            resolve(data.data);
          },
          'error': function(err){
            reject(err);
          }
        });
      });
    }
    else{
      return new Promise(function(resolve,reject){
        var statico=storage.get();
        if(typeof statico.schemas!=='undefined'){
          var filteredData={};
          filteredData=statico.schemas.data.filter(function(f){return (f.id_crop==request.id_crop);});
          resolve(filteredData);
        }
        else{
          reject('No schemas available');
        }
      });
    }
  }

  delete_survey(request){
    var c = confirm("Sei sicuro di voler eliminare il rilievo?");
    if (c) {
      if(!commonJS.is_cordova()){
        return new Promise(function (resolve, reject) {
          var urladdr = global.base_call_aedita+"monitoring_webpack/deleteSurvey";
          jQuery.ajax({
            type: 'POST',
            data:request,
            url: urladdr,
            dataType: "json",
            success: function(data){
              resolve(data.data);
            },
            'error': function(err){
              reject(err);
            }
          });
        });
      }
      else{
        return new Promise(function(resolve,reject){
          var settings=storage.get();


          request.access_token=settings.user.access_token;

          var guid=commonJS.getGuid();

          var staticData=storage.get();
          var newRecord={
            id_survey:request.id_survey,
            // date:request.date,
            id_field:parseInt(request.id_field),
            id_survey_schema:parseInt(request.id_survey_schema),
          };
          // debugger

          if (typeof staticData.toDelete=='undefined') {
            staticData.toDelete=[];
          }

          staticData.toDelete.push(newRecord);

          // staticData.storic_surveys.data.push(newRecord);
          var indexToSplice=null;
          jQuery.each(staticData.storic_surveys.data,function(k,v){
            if (v.id_survey==newRecord.id_survey) {
              console.log("K to splice: "+k);
              indexToSplice=k;
            }
          });

          if (indexToSplice!=null) {
            staticData.storic_surveys.data.splice(indexToSplice,1);
          }

          var indexToSplice2=null;
          // staticData.survey.data.push(newRecord);
          jQuery.each(staticData.survey.data,function(k,v){
            if (v.id_survey==newRecord.id_survey) {
              indexToSplice2=k;
              console.log("J to splice: "+k);
            }
          });

          if (indexToSplice!=null) {
            staticData.survey.data.splice(indexToSplice,1);
          }


          var indexToSplice3=null;
          // staticData.schema_var.survey.data.push(newRecord);
          jQuery.each(staticData.schema_var.survey.data,function(k,v){
            if (v.id_survey==newRecord.id_survey) {
              indexToSplice3=k;
              console.log("I to splice: "+k);
            }
          });

          if (indexToSplice!=null) {
            staticData.schema_var.survey.data.splice(indexToSplice,1);
          }

          storage.save(staticData);
          resolve({'ok':true});
        });
      }
    }
  }


  store_survey(request){
    if(!commonJS.is_cordova()){
      return new Promise(function (resolve, reject) {
        var urladdr = global.base_call_aedita+"monitoring_webpack/storeSurvey";
        jQuery.ajax({
          type: 'POST',
          data:request,
          url: urladdr,
          dataType: "json",
          success: function(data){
            resolve(data.data);
          },
          'error': function(err){
            reject(err);
          }
        });
      });
    }
    else{
      return new Promise(function(resolve,reject){
        var settings=storage.get();

        request.uid=settings.user.uid;

        var guid=commonJS.getGuid();

        var staticData=storage.get();
        var newRecord={
          id_survey: "A_"+Math.round(Math.random() * 100000000000),
          date:request.date,
          id_field:parseInt(request.id_field),
          id_survey_schema:parseInt(request.id_survey_schema),
          val:JSON.stringify(request.varData)
        };

        if (typeof staticData.toStore=='undefined') {
          staticData.toStore=[];
        }

        staticData.toStore.push(newRecord);
        staticData.storic_surveys.data.push(newRecord);
        staticData.survey.data.push(newRecord);
        staticData.schema_var.survey.data.push(newRecord);
        storage.save(staticData);
        resolve({});
      });
    }
  }

  get_storic_survey(request){
    if(!commonJS.is_cordova()){
      return new Promise(function (resolve, reject) {
        var urladdr = global.base_call_aedita+"monitoring_webpack/getStoricSurvey";
        jQuery.ajax({
          type: 'GET',
          async:false,
          data:request,
          url: urladdr,
          dataType: "json",
          success: function(data){
            resolve(data);
          },
          'error': function(err){
            reject(err);
          }
        });
      });
    }
    else{
      return new Promise(function(resolve,reject){
        var statico=storage.get();

        if(typeof statico.storic_surveys!=='undefined'){
          var filteredData={};
          filteredData=statico.storic_surveys.data.filter(function(f){return (f.id_field==request.id_field);});


          var filteredVar={};
          var filteredCode={};
          var filteredSchema={};
          if (typeof request.id_survey_schema!='undefined') {
            filteredData=filteredData.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
            filteredVar=statico.storic_surveys.survey_var.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
            filteredSchema=statico.storic_surveys.survey_schema.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
            filteredCode=statico.storic_surveys.survey_code.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
          }

          var allData={
            data:filteredData,
            survey_var:{
              data:filteredVar
            },
            survey_code:{
              data:filteredCode
            },
            survey_schema:{
              data:filteredSchema
            }
          };

          resolve(allData);
        }
        else{
          reject('No survey available');
        }

      });
    }
  }


  pippo(txt){
    console.log(txt);
  }
}


//La funzione produce una promise
function get_promise(opt, request){
  var statico=storage.get();

  var dataForUser=statico[opt.table_name].data;
  if (opt.table_name!=='schema_var') {
    dataForUser=dataForUser.filter(function(f){return (f.uid==request.uid);})
  }

  if(!commonJS.is_cordova() || dataForUser.length==0){
    return new Promise(function (resolve, reject) {
      var urladdr = global.base_call_aedita+""+opt.path;
      jQuery.ajax({
        type: "POST",
        async:false,
        url: urladdr,
        data:request,
        dataType: "json",
        success: function(data){
          // console.log("JJJAQ"+opt.path);
          resolve(data);
        },
        error: function(e){
          reject(e);
        },
      });
    });
  }
  else{
    return new Promise(function(resolve,reject){

      if (typeof statico[opt.table_name]=='undefined' || opt.table_name=='farm_center' ) {
        console.log("ci passo...");
        sync(function(){console.log("Sync");});
        statico=storage.get();
      }

      var data=statico[opt.table_name];

      var data_ok={};

      if(typeof data!=='undefined'){
        if(jQuery.isEmptyObject(request)){
          data_ok=data.data;
        }
        else{
          // var filter_function=function(f){
          //   var ok=true;
          //   jQuery.each(request,function(k,v){
          //     // console.log(f[k]);
          //     // console.log(request[k]);
          //     ok=ok && (f[k]==request[k]);
          //   });
          //   return ok;
          // };
          //
          // var filteredData=data.data.filter(filter_function);

          var filteredData={};
          // filteredData=statico.survey.data.filter(function(f){return (f.date==request.date);});
          // filteredData=filteredData.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
          if (opt.table_name!=='schema_var') {
            // filteredData=data.data.filter(function(f){return (f.id_user==request.uid);});
          // }
          // else if (opt.table_name=='farm_center') {
              filteredData=data.data.filter(function(f){return (f.uid==request.uid);});
          }
          else {
            filteredData=data;

            // filteredData.data=filteredData.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);});
            // filteredData={
            //   data:data.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);})
            //   data:data.data.filter(function(f){return (f.id_survey_schema==request.id_survey_schema);})
            // };

            // filteredData=data;
          }
          data_ok=filteredData;
        }

        if (opt.table_name!=='schema_var') {
          resolve({'ok': true, 'data':data_ok});
        }
        else {
          resolve(data_ok);
        }
      }
      else{
        reject(' static table '+opt.table_name+' not available');
      }
    });
  }

}




function get_function_api(opt){
  var f=function(success, error,request){
    if(!commonJS.is_cordova()){
      var urladdr = global.base_call_aedita+""+opt.path;
      jQuery.ajax({
        type: 'GET',
        data:request,
        url: urladdr,
        async:false,
        dataType: "json",
        success: function(data){
          success(data);
        },
        'error': function(err){
          success(err);
        }
      });
    }
    else{

      var statico=storage.get();
      data=statico[opt.table_name];
      var data_ok={};

      if(typeof data!=='undefined'){
        if(jQuery.isEmptyObject(request)){
          data_ok=data.data;
        }
        else{
          var filter_function=function(f){
            var ok=true;
            jQuery.each(request,function(k,v){
              ok=ok && (f[k]==request[k]);
            });
            return ok;
          };
          filteredData=data.data.filter(filter_function);

          data_ok=filteredData;
        }

        success({'ok': true, 'data':data_ok});
      }
      else{
        error(''+opt.table_name+' not available');
      }
    }
  };
  return f;
}

export function sync(success, fail, force_update){

  if(typeof force_update=='undefined'){
    force_update=false;
  }
  console.log("sync ....");
  var s=storage.get();
  var all_sync=true;
  var to_sync={
    toStore:[],
    toDelete:[]
  };



  if ((typeof s.toDelete!='undefined' && s.toDelete.length>0) || typeof s.toStore!='undefined' && s.toStore.length>0) {
    if (typeof s.toStore!='undefined' && s.toStore.length>0) {
      to_sync.toStore=s.toStore;
      all_sync=false;

      console.log(s.toStore);
    }

    if (typeof s.toDelete!='undefined' && s.toDelete.length>0) {
      to_sync.toDelete=s.toDelete;
      all_sync=false;

      console.log(s.toDelete);
    }
    success({});
  }

  if (typeof s.farm_center=='undefined') {
    all_sync=false;
  }

  if(all_sync && !force_update){
    console.log("tutto sincronizzato");
    success(s);
  }
  else{

    console.log("da sincronizzare");
    console.log(to_sync);
    // api di aggiornamento


    var access_token="";
    if(s.user){
      access_token=s.user.access_token;
    }
    if(access_token!==''){
      var urladdr = global.base_call_aedita+"monitoring_webpack/sync_survey";
      jQuery.ajax({
        type: "GET",
        async:false,
        url: urladdr,
        data:{
          access_token:access_token,
          data:JSON.stringify(to_sync)
        },
        dataType: "json",
        success: function(data){
          var allOk=true;
          //TODO: occorre verificare che la sincronizzazione abbia avuto successo
          jQuery.each(data,function(k,v){
            jQuery.each(v,function(k2,v2){
              allOk=allOk && v2.ok;
            });
          });
          console.log(allOk);

          // console.log(obj);
          if(allOk){
            var s=storage.get();
            s.toStore=[];
            s.toDelete=[];
            storage.save(s);

            getAppDataAndOverwriteAll(function(data){
              console.log("Dati sincronizzati");
            });

          }
          else{
            if (typeof fail == 'function') {
              fail(data, to_sync);
            }
            else {
              alert("error: "+data.message);
            }
            console.log(data);
          }
        },
        error: function(e){

          console.log("ERRORE");

          global.app.dialog.close();
          console.log(e);
          if(typeof error=='function'){
            error(e);
          }
        },
        timeout: 30000
      });
    }
    else{
      console.log("Utete non loggato. Non si può sincronizzare")
    }
  }
}

export function getAppDataAndOverwriteAll(success, error){
  var s=storage.get();

  if(s.user) {
    var canApi=false;
    jQuery.each(s.user.roles,function(k,v){
      if (v=='monitor' || v=='adminmonit' || v=='administrator') {
        canApi=true;
      }
    });

    if (canApi) {
      var used_jstorage=false;

      // debugger
      var urladdr = global.base_call_aedita+"monitoring_webpack/getAllAppData";
      jQuery.ajax({
        type: "GET",
        async:false,
        url: urladdr,
        data:{
          access_token:s.user.access_token,
        },
        dataType: "json",
        success: function(data){
          // debugger

          // setTimeout(function() {
            global.app.dialog.close();
          // }, 200);

          var s=storage.get();

          var ok=true;
          jQuery.each(data,function(k,v){
            if (!v.ok) {
              ok=false;
            }
          });

          if (!ok) {
            alert(t("C'è stato un errore nel caricamento dei dati statici."));

            // global.crash_log(data,'errore nel caricamento dei dati statici');
          }

          var object = jQuery.extend({}, s, data);

          storage.save(object);

          location.hash='';

        },
        error: function(e){

          global.app.dialog.close();
          console.log(e);
          // alert(e.statusText);
          if(error){
            error(e);
          }
        },
        timeout: 30000
      });
    }
    else {
      // debugger
      global.app.dialog.close();

      console.log(location.hash);
      location.hash = '';
    }
  }
  else {
    // debugger
    global.app.dialog.close();

    console.log(location.hash);
    location.hash = '#login';
  }
}
